import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar, Box, Button, IconButton, Toolbar, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { ReactFCC } from '../../interface/react';
import { pages } from '../../shared/menu';
import useAuth from '../../features/auth/useAuth';
import Logo from '../Logo/Logo';
import ProfileDropdownMenu from '../ProfileDropdownMenu/ProfileDropdownMenu';

interface HeaderProps {}

const Header: ReactFCC<HeaderProps> = () => {
	const { isAuthenticated, isLoadingAuth } = useAuth();
	const { t } = useTranslation();
	const navigate = useNavigate();

	const gotoPage = (path: string) => {
		navigate(path);
	};

	return (
		<AppBar
			color="primary"
			sx={{
				backgroundColor: 'myCustomColor.bgWhite.dark',
				color: 'myCustomColor.textBlack.dark',
			}}
			elevation={1}
		>
			<Toolbar sx={{ gap: 1 }}>
				<Box sx={{ flexGrow: 1 }}>
					<Button disableRipple sx={{ ml: -1, color: 'inherit', display: 'block' }} onClick={() => gotoPage('/')}>
						<Logo />
					</Button>
				</Box>

				{isLoadingAuth ? (
					<Typography variant="overline" color="inherit" noWrap>
						{t('common.loading')}
					</Typography>
				) : (
					<>
						{pages.map((page) => {
							const PageIcon = page.icon;
							return (
								<Box key={page.title}>
									<Button
										size="small"
										sx={{
											color: 'inherit',
											textTransform: 'capitalize',
											display: { xs: 'none', sm: 'flex' },
										}}
										onClick={() => gotoPage(page.path)}
										startIcon={<PageIcon />}
									>
										<Box>{t(`menus.${page.title}`)}</Box>
									</Button>
									<IconButton
										size="small"
										sx={{
											color: 'inherit',
											textTransform: 'capitalize',
											display: { xs: 'flex', sm: 'none' },
										}}
										onClick={() => gotoPage(page.path)}
									>
										<PageIcon />
									</IconButton>
								</Box>
							);
						})}
						<Box sx={{ flexGrow: 0 }}>
							{isAuthenticated ? (
								<ProfileDropdownMenu />
							) : (
								<>
									<Button
										size="small"
										variant="outlined"
										startIcon={<PersonIcon />}
										onClick={() => gotoPage('/login')}
										sx={{
											color: 'inherit',
											borderColor: 'inherit',
											display: { xs: 'none', sm: 'flex' },
										}}
										disabled={isLoadingAuth}
									>
										{t('header.login')}
									</Button>
									<IconButton
										size="small"
										sx={{
											color: 'inherit',
											textTransform: 'capitalize',
											display: { xs: 'flex', sm: 'none' },
										}}
										onClick={() => gotoPage('/login')}
									>
										<PersonIcon />
									</IconButton>
								</>
							)}
						</Box>
					</>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default Header;
