import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ReactFCC } from '../../../../../interface/react';
import { Subscription } from '../../../interface';
import usePayments from '../../../usePayments';
import SubscriptionsCard from '../../../Subscriptions/components/SubscriptionItem/SubscriptionCard';
import StripePriceTable from '../../../components/StripePriceTable/StripePriceTable';
import PricingTable from '../../../Products/components/PricingTable/PricingTable';
import ProductPriceLoader from '../../../components/ProductPricesLoader/ProductPricesLoader';
import useAuth from '../../../../auth/useAuth';
import useProduct from '../../useProduct';

interface ProductBuyProps {}

const ProductBuy: ReactFCC<ProductBuyProps> = () => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const { products, productPrices } = usePayments();
	const { productSlug, product, setProduct, purchasedSubscriptions, hasSubscribed } = useProduct();

	useEffect(() => {
		if (productSlug && products.length) {
			const currentProduct = products.find((productObj) =>
				[productObj.metadata?.slug, productObj.id].includes(productSlug)
			);
			if (currentProduct) {
				setProduct(currentProduct);
			}
		}
	}, [productSlug, products, setProduct]);

	const attrs = {
		'data-testid': 'products-buy-component',
	};

	if (!product) {
		return null;
	}

	return (
		<div {...attrs}>
			<Box
				maxWidth={!hasSubscribed ? 'sm' : 'lg'}
				sx={{
					mx: 'auto',
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					alignItems: !hasSubscribed ? 'stretch' : 'center',
				}}
			>
				{hasSubscribed ? (
					<>
						<Typography
							variant="h6"
							gutterBottom
							sx={{
								fontSize: {
									xs: '1rem',
									sm: '1.25rem',
								},
								mb: {
									xs: 2,
									sm: 3,
								},
							}}
						>
							{t('product_details.my_subscription')}
						</Typography>
						<Box
							sx={{
								// width: '100%',
								display: 'flex',
								flexDirection: 'row',
								gap: 2,
								alignItems: 'stretch',
								flexWrap: 'wrap',
								mb: 2,
							}}
						>
							{purchasedSubscriptions.map((subscription: Subscription) => (
								<SubscriptionsCard key={subscription.id} subscription={subscription} />
							))}
						</Box>
					</>
				) : (
					<Box>
						{product.metadata.pricingTableId && product.metadata.publishableKey ? (
							<StripePriceTable
								pricingTableId={product.metadata.pricingTableId}
								publishableKey={product.metadata.publishableKey}
								clientReferenceId={user?.uid}
								{...(user?.email
									? {
											customerEmail: user?.email,
									  }
									: {})}
							/>
						) : (
							<ProductPriceLoader product={product}>
								<PricingTable product={product} productPrices={productPrices} />
							</ProductPriceLoader>
						)}
					</Box>
				)}
			</Box>
		</div>
	);
};

export default ProductBuy;
