import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardHeader, IconButton, LinearProgress, Typography } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import HttpsIcon from '@mui/icons-material/Https';
import CloseIcon from '@mui/icons-material/Close';
import { ReactFCC } from '../../../../../interface/react';
import { ErrorMessage, Product, ProductPrice } from '../../../interface';
import { addCheckoutSession, getDocFromPathById } from '../../../service';
import useAuth from '../../../../auth/useAuth';
import { getFormattedCurrency, intervalText, sortPricings } from '../../../utils';
import { ProductPricesObject } from '../../../PaymentsProvider';

interface PricingTableProps {
	product: Product;
	productPrices: ProductPricesObject;
}

const PricingTable: ReactFCC<PricingTableProps> = (props) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { product, productPrices } = props;
	const { user, isAuthenticated } = useAuth();
	const [plan, setPlan] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState<ErrorMessage | null>(null);

	const prices = useMemo(() => {
		return (productPrices[product.id] || []).sort(sortPricings);
	}, [productPrices, product.id]);

	const selectedPriceDetails = useMemo(() => prices.find((price: ProductPrice) => price.id === plan), [prices, plan]);

	const getDocFromPath = useCallback(async (newDocRef: any, count: number) => {
		return new Promise((resolve, reject) => {
			setTimeout(async () => {
				count++;
				const newDoc = await getDocFromPathById(newDocRef.parent.path, newDocRef.id);
				if (newDoc.error) {
					reject(newDoc);
				}
				if (!newDoc.url) {
					if (count < 5) {
						resolve(getDocFromPath(newDocRef, count));
					} else {
						reject(new Error('Error getting checkout session'));
					}
				} else {
					resolve(newDoc);
				}
			}, 1000);
		});
	}, []);

	const buyNowByPrice = useCallback(
		async (priceDetails: ProductPrice) => {
			setLoading(true);
			setError(null);
			const selectedPrice: any = {
				price: priceDetails?.id,
			};
			if (priceDetails?.recurring?.usage_type !== 'metered') {
				selectedPrice.quantity = 1;
			}
			const checkoutSession: any = {
				automatic_tax: true,
				tax_id_collection: true,
				collect_shipping_address: true,
				allow_promotion_codes: true,
				line_items: [selectedPrice],
				success_url: window.location.href,
				cancel_url: window.location.href,
				metadata: {
					key: 'value',
				},
			};
			// For one time payments set mode to payment.
			if (priceDetails?.type === 'one_time') {
				checkoutSession.mode = 'payment';
				checkoutSession.payment_method_types = ['card'];
			}

			const newDocRef = await addCheckoutSession(user?.uid as string, checkoutSession);
			getDocFromPath(newDocRef, 0)
				.then((newDoc: any) => {
					if (newDoc.error) {
						console.error(newDoc.error);
						setError(newDoc.error);
					}
					if (newDoc.url) {
						window.location.assign(newDoc.url);
					}
				})
				.catch((err: any) => {
					console.error(err);
					setError(err);
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[user, setError, setLoading, getDocFromPath]
	);

	const goToLogin = () => {
		navigate(`/login?redirect=${window.location.pathname}`);
	};

	const onBuyNow = async () => {
		if (!isAuthenticated) {
			goToLogin();
		}
		if (selectedPriceDetails) {
			buyNowByPrice(selectedPriceDetails);
		}
	};

	const loadingStyles = loading ? { opacity: 0.5, pointerEvents: 'none', userSelect: 'none', cursor: 'progress' } : {};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, position: 'relative', ...loadingStyles }}>
			{loading && (
				<Box sx={{ width: '100%', mt: 2, zIndex: 1 }}>
					<LinearProgress />
				</Box>
			)}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					gap: 3,
				}}
			>
				{prices.length > 0 &&
					prices.map((price: ProductPrice) => {
						const action =
							plan === price.id ? (
								<IconButton disableRipple disabled={loading || plan !== price.id} color="inherit">
									<CheckCircleOutlineIcon color="inherit" />
								</IconButton>
							) : (
								<IconButton disableRipple color="inherit">
									<RadioButtonUncheckedIcon color="inherit" />
								</IconButton>
							);
						return (
							<Card
								key={price.id}
								onClick={() => setPlan(price.id)}
								sx={{
									cursor: 'pointer',
									border: '1px solid transparent',
									borderColor: plan === price.id ? '#fff' : '#9892ffd4',
									backgroundColor: plan === price.id ? '#625afa' : '',
									color: plan === price.id ? '#fff' : '#625afa',
									borderRadius: 10,
									textAlign: 'center',
									transform: plan === price.id ? 'scale(1.05)' : 'scale(1)',
									transition: 'all 0.2s ease-in-out',
								}}
								elevation={plan === price.id ? 4 : 1}
							>
								<CardHeader
									sx={{
										'.MuiCardHeader-action': {
											alignSelf: 'center',
										},
									}}
									title={<Typography variant="h6">{getFormattedCurrency(price)}</Typography>}
									subheader={
										<Typography variant="caption" component="div">
											{`${intervalText(price, t)} ${price.description || ''}`}
										</Typography>
									}
									action={action}
								/>
							</Card>
						);
					})}
			</Box>
			{plan && (
				<Box
					sx={{
						textAlign: 'center',
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-end',
						gap: 2,
						mb: 2,
					}}
				>
					<Typography variant="h4" fontWeight={400} color="#0074d4">
						{selectedPriceDetails && getFormattedCurrency(selectedPriceDetails)}
					</Typography>
					{isAuthenticated ? (
						<Button
							size="large"
							variant="contained"
							startIcon={<ShoppingCartCheckoutIcon />}
							endIcon={<HttpsIcon />}
							onClick={onBuyNow}
							disabled={loading}
							sx={{
								backgroundColor: '#0074d4',
								borderRadius: 10,
								fontSize: '1.2rem',
							}}
						>
							{t('products.buy_now')}
						</Button>
					) : (
						<Button
							size="large"
							variant="contained"
							startIcon={<PersonIcon />}
							onClick={goToLogin}
							sx={{
								backgroundColor: '#0074d4',
								borderRadius: 10,
								fontSize: '1rem',
							}}
						>
							{t('products.login_to_buy')}
						</Button>
					)}
				</Box>
			)}
			{error && (
				<Box
					sx={{
						p: 1,
						backgroundColor: 'error.main',
						color: 'error.contrastText',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Typography variant="caption">{error.message}</Typography>
					<IconButton
						onClick={() => setError(null)}
						sx={{ color: '#fff', border: '1px solid', m: 1, width: 32, height: 32 }}
					>
						<CloseIcon />
					</IconButton>
				</Box>
			)}
		</Box>
	);
};

export default PricingTable;
