import { useContext } from 'react';
import { PaymentsContext } from './PaymentsProvider';

const usePayments = () => {
	const context = useContext(PaymentsContext);
	if (!context) {
		throw new Error('usePayments must be used within an PaymentsProvider');
	}
	return context;
};

export default usePayments;
