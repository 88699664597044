import React, { useCallback, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Paper, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { ReactFCC } from '../../../../../interface/react';
import usePayments from '../../../usePayments';
import useProduct from '../../useProduct';
import useAuth from '../../../../auth/useAuth';

interface ProductPlansProps {}

const ProductPlans: ReactFCC<ProductPlansProps> = () => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const { isAuthenticated } = useAuth();
	const { products } = usePayments();
	const { productSlug, product, setProduct, hasSubscribed } = useProduct();
	const { productPlan } = useProduct();

	useEffect(() => {
		if (productSlug && products.length) {
			const currentProduct = products.find((productObj) =>
				[productObj.metadata?.slug, productObj.id].includes(productSlug)
			);
			if (currentProduct) {
				setProduct(currentProduct);
			}
		}
	}, [productSlug, products, setProduct]);

	const goToBuyPage = useCallback(() => {
		const slug = productSlug || '';
		const path = `${pathname.split(slug)[0]}${slug}/buy`;
		navigate(path);
	}, [pathname, navigate, productSlug]);

	const onManageSubscriptionClick = useCallback(() => {
		goToBuyPage();
	}, [goToBuyPage]);

	const onGetStartedFreeClick = useCallback(() => {
		navigate(`/login?redirect=${window.location.pathname}`);
	}, [navigate]);

	const onGetStartedPremiumClick = useCallback(() => {
		goToBuyPage();
	}, [goToBuyPage]);

	const getActionButton = useCallback(
		(requireSubscription: boolean) => {
			const getStartedFree = (
				<Box
					sx={{
						width: '100%',
					}}
				>
					<Button size="large" fullWidth variant="outlined" onClick={onGetStartedFreeClick}>
						{t('product_plans.get_started')}
					</Button>
				</Box>
			);
			const getStartedPremium = (
				<Box
					sx={{
						width: '100%',
					}}
				>
					<Button size="large" fullWidth variant="outlined" onClick={onGetStartedPremiumClick}>
						{t('product_plans.get_started')}
					</Button>
				</Box>
			);
			const manageSubscription = (
				<Box
					sx={{
						width: '100%',
					}}
				>
					<Button size="large" fullWidth variant="outlined" onClick={onManageSubscriptionClick}>
						{t('product_plans.manage_subscription')}
					</Button>
				</Box>
			);
			const alreadyIncluded = (
				<Box
					sx={{
						cursor: 'not-allowed',
						width: '100%',
					}}
				>
					<Button size="large" fullWidth variant="outlined" disabled>
						{t('product_plans.included_plan')}
					</Button>
				</Box>
			);
			const currentPlan = (
				<Box
					sx={{
						cursor: 'not-allowed',
						width: '100%',
					}}
				>
					<Button size="large" fullWidth variant="outlined" disabled>
						{t('product_plans.current_plan')}
					</Button>
				</Box>
			);

			if (!isAuthenticated) {
				return requireSubscription ? getStartedPremium : getStartedFree;
			}

			if (requireSubscription) {
				// is paid plan
				return hasSubscribed ? manageSubscription : getStartedPremium;
			}

			// free plan
			return hasSubscribed ? alreadyIncluded : currentPlan;
		},
		[isAuthenticated, t, hasSubscribed, onGetStartedFreeClick, onGetStartedPremiumClick, onManageSubscriptionClick]
	);

	const attrs = {
		'data-testid': 'products-plans-component',
	};

	if (!product) {
		return null;
	}

	if (!productPlan) {
		if (!pathname.endsWith('plans')) {
			return null;
		}

		return (
			<div {...attrs}>
				<Box sx={{ textAlign: 'center' }}>
					<Typography variant="h5" component="h2">
						{t('product_plans.no_plans_available')}
					</Typography>
				</Box>
			</div>
		);
	}

	return (
		<div {...attrs}>
			<Paper
				sx={{
					display: 'flex',
					flexDirection: {
						xs: 'column',
						sm: 'row',
					},
					gap: {
						xs: 2,
						sm: 3,
						md: 5,
					},
					alignItems: 'stretch',
					justifyItems: 'stretch',
					p: {
						xs: 2,
						sm: 3,
						md: 5,
					},
					my: 5,
					background: 'linear-gradient(106deg, #fe6b8b 30%, #ff8e53 90%)',
				}}
				elevation={5}
			>
				{Object.values(productPlan).map((plan) => {
					return (
						<Card
							key={plan.title}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								alignItems: 'stretch',
								flex: { xs: 'auto', sm: '1 1 0' },
								backgroundColor: 'grey.50',
								padding: 2,
							}}
						>
							<CardHeader
								title={
									<Typography
										variant="h4"
										sx={{
											textTransform: 'capitalize',
											fontWeight: '600',
										}}
									>
										{t(`product_plans.title_${plan.title}`)}
									</Typography>
								}
							/>
							<CardContent>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										gap: 1,
									}}
								>
									{plan?.features.map((feature) => {
										return (
											<Typography
												key={feature}
												variant="body2"
												sx={{
													display: 'flex',
													alignItems: 'flex-start',
													gap: 2,
													color: 'grey.700',
												}}
											>
												<DoneIcon sx={{ color: 'success.main' }} fontSize="small" />
												{feature}
											</Typography>
										);
									})}
								</Box>
							</CardContent>
							<CardActions>{getActionButton(plan.requireSubscription)}</CardActions>
						</Card>
					);
				})}
			</Paper>
		</div>
	);
};

export default ProductPlans;
