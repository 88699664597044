import React, { useEffect } from 'react';
import { ReactFCC } from '../../interface/react';
import { trackPageView } from '../../common/ga4';
import Payments from '../../features/Payments/Payments';

interface PaymentsPageProps {}

const PaymentsPage: ReactFCC<PaymentsPageProps> = () => {
	useEffect(() => {
		trackPageView();
	}, []);

	return (
		<div data-testid="payments-page-component">
			<Payments />
		</div>
	);
};

export default PaymentsPage;
