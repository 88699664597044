import config from '../../config/config';
import { DOWNLOAD_LINKS } from './enum';

export const PAYMENTS_PATH = '/payments';

export const paymentTabsLabels = ['products', 'subscriptions', 'verification'];

export const createPortalLinkCloudFunctionName = config.FIREBASE_CUSTOMER_PORTAL_CLOUD_FUNC_NAME as string;

export const downloadLinkItems: DOWNLOAD_LINKS[] = [DOWNLOAD_LINKS.chrome, DOWNLOAD_LINKS.firefox, DOWNLOAD_LINKS.edge];

export const downloadLinkItemIcons: { [key in DOWNLOAD_LINKS]: string } = {
	[DOWNLOAD_LINKS.chrome]: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/chrome/chrome-original.svg',
	[DOWNLOAD_LINKS.firefox]: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firefox/firefox-original.svg',
	[DOWNLOAD_LINKS.edge]: 'https://cdn.jsdelivr.net/gh/devicons/devicon/icons/ie10/ie10-original.svg',
};

export const downloadLinkLabels: { [key in DOWNLOAD_LINKS]: string } = {
	[DOWNLOAD_LINKS.chrome]: 'Chrome',
	[DOWNLOAD_LINKS.firefox]: 'Firefox',
	[DOWNLOAD_LINKS.edge]: 'Edge',
};

export const stripeSupportedCountries: string[] = [
	'AU',
	'AT',
	'BE',
	'BR',
	'BG',
	'CA',
	'HR',
	'CY',
	'CZ',
	'DK',
	'EE',
	'FI',
	'FR',
	'DE',
	'GI',
	'GR',
	'HK',
	'HU',
	'IN',
	'ID',
	'IE',
	'IT',
	'JP',
	'LV',
	'LI',
	'LT',
	'LU',
	'MY',
	'MT',
	'MX',
	'NL',
	'NZ',
	'NO',
	'PL',
	'PT',
	'RO',
	'SG',
	'SK',
	'SI',
	'ES',
	'SE',
	'CH',
	'TH',
	'AE',
	'GB',
	'US',
];
