import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactFCC } from '../../../interface/react';
import { PAYMENTS_PATH } from '../constants';

interface PaymentsHomeProps {}

const PaymentsHome: ReactFCC<PaymentsHomeProps> = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(`${PAYMENTS_PATH}/products`);
	}, [navigate]);

	return <div data-testid="payments-home-component">Payments Home</div>;
};

export default PaymentsHome;
