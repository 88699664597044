import { PaletteMode } from '@mui/material';

const getDesignTokens = (mode: PaletteMode) => ({
	palette: {
		mode,
		...(mode === 'light'
			? {
					myCustomColor: {
						bgColor: { main: '#111111', light: '#222222', dark: '#000000' },
						textColor: { main: '#eeeeee', light: '#dddddd', dark: '#ffffff' },
						bgBlack: { main: '#111111', light: '#222222', dark: '#000000' },
						textWhite: { main: '#eeeeee', light: '#dddddd', dark: '#ffffff' },
						bgWhite: { main: '#eeeeee', light: '#dddddd', dark: '#ffffff' },
						textBlack: { main: '#111111', light: '#222222', dark: '#000000' },
					},
			  }
			: {
					myCustomColor: {
						bgColor: { main: '#eeeeee', light: '#dddddd', dark: '#ffffff' },
						textColor: { main: '#111111', light: '#222222', dark: '#000000' },
						bgBlack: { main: '#111111', light: '#222222', dark: '#000000' },
						textWhite: { main: '#eeeeee', light: '#dddddd', dark: '#ffffff' },
						bgWhite: { main: '#222222', light: '#333333', dark: '#111111' },
						textBlack: { main: '#eeeeee', light: '#dddddd', dark: '#ffffff' },
					},
			  }),
	},
});

export default getDesignTokens;
