import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	LinearProgress,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactFCC } from '../../interface/react';
import Verification from '../Payments/components/Verification/Verification';
import useAuth from '../auth/useAuth';
import usePayments from '../Payments/usePayments';
import { Product, Subscription } from '../Payments/interface';
import { DataForExtension } from '../Payments/components/Verification/interface';
import { cleanupDataForExtension } from '../Payments/utils';

interface ExtensionAuthProps {}

const ExtensionAuth: ReactFCC<ExtensionAuthProps> = () => {
	const { t } = useTranslation();
	const { isAuthenticated } = useAuth();
	const {
		products,
		subscriptions,
		useRequestSubscriptionsRef: {
			refetch: refetchSubscriptions,
			isLoading: isLoadingSubscriptions,
			isFetching: isFetchingSubscriptions,
		},
		useRequestProductsRef: { refetch: refetchProducts, isLoading: isLoadingProducts, isFetching: isFetchingProducts },
	} = usePayments();
	const productsToSelect: Product[] = useMemo(() => {
		return products.filter((product) => product.metadata.extensionid);
	}, [products]);
	const [selectedProductId, setSelectedProductId] = React.useState<string>('');
	const selectedProductData: Product = useMemo(() => {
		return (products.find((product) => product.id === selectedProductId) || {}) as Product;
	}, [products, selectedProductId]);
	const purchasedSubscription = useMemo(() => {
		return subscriptions.find((subscription) => subscription.product.id === selectedProductData?.id);
	}, [subscriptions, selectedProductData]);

	const dataForExtension: DataForExtension = useMemo(() => {
		return cleanupDataForExtension(selectedProductData, (purchasedSubscription || {}) as Subscription);
	}, [selectedProductData, purchasedSubscription]);

	const isLoadingData = isLoadingSubscriptions || isLoadingProducts;
	const isFetchingData = isFetchingSubscriptions || isFetchingProducts;

	const refetch = useCallback(() => {
		setTimeout(() => {
			refetchSubscriptions();
			refetchProducts();
		}, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isAuthenticated && !subscriptions.length && !isLoadingSubscriptions && !isFetchingSubscriptions) {
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (productsToSelect.length) {
			setSelectedProductId(productsToSelect[0].id);
		}
	}, [productsToSelect]);

	const attrs = {
		'data-testid': 'subscriptions-component',
	};

	const selectProduct = useCallback((event: SelectChangeEvent<unknown>) => {
		const productId = event.target.value as string;
		setSelectedProductId(productId);
	}, []);

	if (isLoadingData) {
		return (
			<Box {...attrs}>
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			</Box>
		);
	}

	return (
		<div data-testid="extension-auth-component">
			<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Box sx={{ width: '100%' }}>{isFetchingData && <LinearProgress />}</Box>
				<IconButton onClick={refetch} disabled={isFetchingProducts}>
					<RefreshIcon />
				</IconButton>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch',
					justifyContent: 'center',
					gap: 5,
					mb: 5,
				}}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 2,
					}}
				>
					<Typography variant="h6">{t('Please select an Extension')}</Typography>
					<Select value={selectedProductId} onChange={selectProduct}>
						{selectedProductId &&
							productsToSelect.map((productData: Product) => {
								return (
									<MenuItem key={productData.id} value={productData.id}>
										{productData.name}
									</MenuItem>
								);
							})}
					</Select>
				</Box>
				{selectedProductId && (
					<Card
						sx={{
							mx: 'auto',
							width: '100%',
							maxWidth: 480,
							borderRadius: 3,
						}}
						elevation={1}
					>
						<CardHeader
							sx={{
								backgroundColor: 'grey.200',
							}}
							avatar={<Avatar src={selectedProductData?.images?.[0]} sx={{ width: 64, height: 64 }} />}
							title={
								<Typography variant="body2" fontWeight={500} color="inherit">
									{selectedProductData?.name}
								</Typography>
							}
							subheader={
								<Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, mt: 1 }}>
									{selectedProductData?.metadata?.extensionid?.split(',').map((extensionId: string) => {
										return (
											<Typography key={extensionId} variant="caption" component="div">
												{extensionId}
											</Typography>
										);
									})}
								</Box>
							}
						/>
						{selectedProductData?.metadata?.extensionid ? (
							<CardContent
								sx={{
									p: 2,
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'space-between',
								}}
							>
								<Box
									sx={{
										mt: 1,
										display: 'flex',
										flexDirection: 'column',
										gap: 2,
									}}
								>
									{selectedProductData?.metadata?.extensionid?.split(',').map((extensionId) => (
										<Box
											key={extensionId}
											sx={{
												display: 'flex',
												flexDirection: 'column',
												gap: 1,
												borderRadius: 1,
												backgroundColor: 'grey.200',
												p: 2,
											}}
										>
											<Typography variant="body2" fontWeight={500} gutterBottom textAlign="center" color="primary">
												{t('Extension ID')}: {extensionId}
											</Typography>
											<Verification
												key={extensionId}
												browserExtensionId={extensionId}
												mode="button"
												dataForExtension={dataForExtension}
											/>
										</Box>
									))}
								</Box>
							</CardContent>
						) : (
							'No extension id'
						)}
					</Card>
				)}
			</Box>
		</div>
	);
};

export default ExtensionAuth;
