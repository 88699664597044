import LogoutIcon from '@mui/icons-material/Logout';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import config from '../config/config';

interface Page {
	title: string;
	icon: any;
	path: string;
}

export const pages: Page[] = [
	{ title: 'payment', icon: AccountBalanceWalletIcon, path: '/payments/products' },
	{ title: 'login_to_browser_extension', icon: InstallDesktopIcon, path: '/payments/verification' },
];

export const settings: Page[] = [{ title: 'logout', icon: LogoutIcon, path: '/logout' }];

const websiteUrl = config.WEBSITE_URL || window.location.origin;

export const links = {
	privacyPolicy: `${websiteUrl}/privacy-policy`,
	termsConditions: `${websiteUrl}/terms-conditions`,
};
