import React, { Dispatch, SetStateAction, createContext, useState } from 'react';
import {
	useGetBillingPortalLink,
	useGetCustomClaimRole,
	// useGetProductPrices,
	useGetProducts,
	useGetSubscription,
} from './useRequest';
import { ReactFCC } from '../../interface/react';
import { CustomerPortalResponse, Product, ProductPrice, Subscription } from './interface';
import useAuth from '../auth/useAuth';

export interface ProductPricesObject {
	[productId: string]: ProductPrice[];
}

export interface PricesObject {
	[priceId: string]: ProductPrice;
}

interface PaymentsProviderContext {
	products: Product[];
	setProducts: Dispatch<SetStateAction<Product[]>>;
	productPrices: ProductPricesObject;
	setProductPrices: Dispatch<SetStateAction<ProductPricesObject>>;
	prices: PricesObject;
	setPrices: Dispatch<SetStateAction<PricesObject>>;
	customerPortalUrl: string;
	useRequestProductsRef: any;
	useRequestSubscriptionsRef: any;
	useRequestCustomClaimRoleRef: any;
	getBillingPortalLinkRef: any;
	subscriptions: Subscription[];
	customClaimRole: string;
}

const defaultValues = {} as PaymentsProviderContext;

export const PaymentsContext = createContext<PaymentsProviderContext>(defaultValues);

interface PaymentsProviderProps {}

export const PaymentsProvider: ReactFCC<PaymentsProviderProps> = ({ children }) => {
	const [products, setProducts] = useState<Product[]>([]);
	const [productPrices, setProductPrices] = useState<ProductPricesObject>({});
	const [prices, setPrices] = useState<PricesObject>({});
	const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
	const [customClaimRole, setCustomClaimRole] = useState<string>('');
	const [customerPortalUrl, setCustomerPortalUrl] = useState<string>('');
	const { user } = useAuth();

	// useGetProductPrices(
	// 	{ productIds: () => products.map((product: Product) => product.id) },
	// 	{
	// 		onSuccess: (productPricesRes: ProductPrice[]) => {
	// 			if (productPricesRes.length === 0) return;
	// 			const productId = productPricesRes[0].product;
	// 			setProductPrices((prevProductPrices: ProductPricesObject) => {
	// 				const updatedProductPrices = { ...prevProductPrices, [productId]: productPricesRes };
	// 				return updatedProductPrices;
	// 			});
	// 			setPrices((prevPrices: PricesObject) => {
	// 				const pricesByPriceId = productPricesRes.reduce((acc: any, price: ProductPrice) => {
	// 					return { ...acc, [price.id]: price };
	// 				}, {});
	// 				const updatedPrices = { ...prevPrices, ...pricesByPriceId };
	// 				return updatedPrices;
	// 			});
	// 		},
	// 		onError: (err: any) => {
	// 			console.log(err);
	// 		},
	// 		enabled: products.length > 0,
	// 	}
	// );

	const useRequestProductsRef = useGetProducts({
		onSuccess: (resProducts: Product[]) => {
			setProducts(resProducts);
		},
		onError: (err: any) => {
			console.log(err);
		},
		enabled: false,
	});

	const useRequestSubscriptionsRef = useGetSubscription(
		{ userId: user?.uid || '' },
		{
			onSuccess: (resSubscriptions: Subscription[]) => {
				setSubscriptions(resSubscriptions);
			},
			onError: (err: any) => {
				console.log(err);
			},
			enabled: false,
		}
	);

	const useRequestCustomClaimRoleRef = useGetCustomClaimRole(
		{ user: user || {} },
		{
			onSuccess: (resCustomClaimRole: string) => {
				setCustomClaimRole(resCustomClaimRole);
			},
			onError: (err: any) => {
				console.log(err);
			},
			enabled: false,
		}
	);

	const getBillingPortalLinkRef = useGetBillingPortalLink({
		onSuccess: (res: CustomerPortalResponse) => {
			setCustomerPortalUrl(res.url);
		},
		onError: (err: any) => {
			console.log(err);
		},
		enabled: subscriptions.length > 0,
	});

	// useEffect(() => {
	// 	if (user?.uid && !customClaimRole) {
	// 		useRequestCustomClaimRoleRef.refetch();
	// 	}
	// }, [user, customClaimRole, useRequestCustomClaimRoleRef]);

	const contextValue = React.useMemo(
		() => ({
			products,
			setProducts,
			useRequestProductsRef,
			productPrices,
			setProductPrices,
			prices,
			setPrices,
			customerPortalUrl,
			useRequestSubscriptionsRef,
			useRequestCustomClaimRoleRef,
			getBillingPortalLinkRef,
			subscriptions,
			customClaimRole,
		}),
		[
			products,
			setProducts,
			useRequestProductsRef,
			productPrices,
			setProductPrices,
			prices,
			setPrices,
			customerPortalUrl,
			useRequestSubscriptionsRef,
			useRequestCustomClaimRoleRef,
			getBillingPortalLinkRef,
			subscriptions,
			customClaimRole,
		]
	);

	return <PaymentsContext.Provider value={contextValue}>{children}</PaymentsContext.Provider>;
};

export default PaymentsProvider;
