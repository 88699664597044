import React, { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Tooltip } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { ObjectWithAnyValues } from '../../../../interface/react';
import useAuth from '../../../auth/useAuth';
import { getIdToken } from '../../service';
import { StatusesObj, VERIFICATION_ACTIONS, statuses } from './const';
import { DataForExtension } from './interface';

interface UseBrowserProps {
	browserExtensionId: string;
	delayBetweenProcesses?: number;
	dataForExtension?: DataForExtension;
}

interface BrowserMessageData {
	action: string;
	token?: string;
	userProfile?: ObjectWithAnyValues;
	data?: DataForExtension;
}

const useBrowser = (props: UseBrowserProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useAuth();
	const { browserExtensionId, delayBetweenProcesses, dataForExtension } = props;
	const [plannedDelay, setPlannedDelay] = useState<number>(delayBetweenProcesses || 0);
	const [isExtensionInstalled, setIsExtensionInstalled] = useState<boolean | null>(null);
	const [isExtensionVerified, setIsExtensionVerified] = useState<boolean | null>(null);
	const [isExtensionAuthenticated, setIsExtensionAuthenticated] = useState<boolean | null>(null);
	const [extensionLoginUserDetails, setExtensionLoginUserDetails] = useState<any>(null); // [user, token
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [extensionVersion, setExtensionVersion] = useState<string>('');
	const [token, setToken] = useState<string>('');
	const [steps, setSteps] = useState<any[]>([]);
	const [error, setError] = useState<any>(null);

	const processStatus: StatusesObj = useMemo(() => {
		if (isProcessing) {
			return statuses.processing;
		}
		if (error) {
			return { ...statuses.error, ...(error?.message ? { message: error?.message } : '') };
		}

		if (isExtensionVerified === true) {
			return statuses.extension_auth_check_success;
		}
		if (isExtensionVerified === false) {
			return statuses.extension_auth_check_failed;
		}

		if (isExtensionInstalled === true) {
			return statuses.extension_installation_check_success;
		}
		if (isExtensionInstalled === false) {
			return statuses.extension_installation_check_failed;
		}

		if (isExtensionInstalled === null) {
			return statuses.extension_installation_check;
		}
		if (isExtensionVerified === null) {
			return statuses.extension_auth_check;
		}

		return { message: 'Unknown', icon: <div /> };
	}, [isExtensionInstalled, isExtensionVerified, isProcessing, error]);

	const goToExtensionAuth = () => {
		navigate('/extension-auth');
	};

	const timedPromise = useCallback((ms: number) => {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve(true);
			}, ms);
		});
	}, []);

	const sendMessageToExtension = useCallback(
		(data: BrowserMessageData, before: () => void) => {
			return new Promise((resolve, reject) => {
				if (typeof before === 'function') {
					before();
				}
				try {
					chrome.runtime.sendMessage(browserExtensionId, { data }, { includeTlsChannelId: false }, async (response) => {
						if (plannedDelay) {
							await timedPromise(plannedDelay);
						}
						if (response?.data) {
							resolve(response?.data);
						} else {
							reject(new Error('No response from extension'));
						}
					});
				} catch (err) {
					// console.log(err);
					(async () => {
						if (plannedDelay) {
							await timedPromise(plannedDelay);
						}
						reject(err);
					})();
				}
			});
		},
		[browserExtensionId, plannedDelay, timedPromise]
	);

	const checkIfExtensionIsInstalled = useCallback(() => {
		return sendMessageToExtension({ action: VERIFICATION_ACTIONS.INSTALLATION_CHECK }, () => {
			setSteps((prevSteps) => [...prevSteps, 'extension_installation_check']);
			setIsProcessing(true);
		})
			.then((data: any) => {
				if (localStorage.debug === 'development') console.log(VERIFICATION_ACTIONS.INSTALLATION_CHECK, data);
				setExtensionVersion(data?.version || '');
				setIsExtensionInstalled(true);
				setIsProcessing(false);
				setSteps((prevSteps) => [...prevSteps, 'extension_installation_check_success']);
				return data;
			})
			.catch((err) => {
				if (localStorage.debug === 'development') console.log(err);
				setIsExtensionInstalled(false);
				setIsProcessing(false);
				setSteps((prevSteps) => [...prevSteps, 'extension_installation_check_failed']);
				throw err;
				return err;
			});
	}, [setIsExtensionInstalled, setIsProcessing, setSteps, sendMessageToExtension]);

	const checkIfExtensionIsAuthenticated = useCallback(async () => {
		return sendMessageToExtension({ action: VERIFICATION_ACTIONS.AUTHENTICATION_CHECK }, () => {
			setSteps((prevSteps) => [...prevSteps, 'extension_auth_check']);
			setIsProcessing(true);
		})
			.then((data: any) => {
				if (localStorage.debug === 'development') console.log(VERIFICATION_ACTIONS.AUTHENTICATION_CHECK, data);
				setIsExtensionAuthenticated(data?.authentication === true);
				setExtensionLoginUserDetails(data?.userProfile);
				setIsProcessing(false);
				setSteps((prevSteps) => [...prevSteps, 'extension_auth_check_success']);
				return data;
			})
			.catch((err) => {
				if (localStorage.debug === 'development') console.log(err);
				setIsExtensionAuthenticated(false);
				setIsProcessing(false);
				setSteps((prevSteps) => [...prevSteps, 'extension_auth_check_failed']);
				throw err;
				return err;
			});
	}, [setIsProcessing, sendMessageToExtension]);

	const loginToExtension = useCallback(
		(idToken: string) => {
			return sendMessageToExtension(
				{
					action: VERIFICATION_ACTIONS.SET_AUTHENTICATION,
					token: idToken,
					userProfile: {
						uid: user?.uid,
						email: user?.email || '',
						photoURL: user?.photoURL || '',
						displayName: user?.displayName || '',
						metadata: { lastSignInTime: user?.metadata?.lastSignInTime || '' },
					},
					...(dataForExtension && { data: dataForExtension }),
				},
				() => {
					setSteps((prevSteps) => [...prevSteps, 'extension_sign_in']);
					setIsProcessing(true);
				}
			)
				.then((data: any) => {
					if (localStorage.debug === 'development') console.log(VERIFICATION_ACTIONS.SET_AUTHENTICATION, data);

					setIsExtensionVerified(data?.authentication === true);
					setIsProcessing(false);
					setSteps((prevSteps) => [...prevSteps, 'extension_sign_in_success']);
					return data;
				})
				.catch((err) => {
					if (localStorage.debug === 'development') console.log(err);
					setIsExtensionVerified(false);
					setIsProcessing(false);
					setSteps((prevSteps) => [...prevSteps, 'extension_sign_in_failed']);
					throw err;
					return err;
				});
		},
		[setIsExtensionVerified, setIsProcessing, setSteps, sendMessageToExtension, user, dataForExtension]
	);

	const logoutFromExtension = useCallback(() => {
		return sendMessageToExtension({ action: VERIFICATION_ACTIONS.UNSET_AUTHENTICATION }, () => {
			setSteps((prevSteps) => [...prevSteps, 'extension_logout']);
			setIsProcessing(true);
		})
			.then((data: any) => {
				if (localStorage.debug === 'development') console.log(VERIFICATION_ACTIONS.UNSET_AUTHENTICATION, data);
				setIsExtensionVerified(data?.authentication === true);
				setIsProcessing(false);
				setSteps((prevSteps) => [...prevSteps, 'extension_logout_success']);
				return data;
			})
			.catch((err) => {
				if (localStorage.debug === 'development') console.log(err);
				setIsExtensionVerified(true);
				setIsProcessing(false);
				setSteps((prevSteps) => [...prevSteps, 'extension_logout_failed']);
				throw err;
				return err;
			});
	}, [setIsExtensionVerified, setIsProcessing, setSteps, sendMessageToExtension]);

	const getAuthToken = useCallback(() => {
		setIsProcessing(true);
		return getIdToken()
			.then((idToken) => {
				setToken(idToken || '');
				setSteps((prevSteps) => [...prevSteps, 'user_check_success']);
				return idToken;
			})
			.catch((err) => {
				setError(err);
				setSteps((prevSteps) => [...prevSteps, 'user_check_failed']);
				throw err;
				return err;
			})
			.finally(() => {
				setIsProcessing(false);
			});
	}, [setIsProcessing, setToken, setError, setSteps]);

	const showLinkToVerification = isExtensionInstalled ? (
		<Tooltip title={t('verification.verify_icon_title')}>
			<IconButton size="small" onClick={goToExtensionAuth}>
				<VerifiedUserIcon fontSize="inherit" color="success" />
			</IconButton>
		</Tooltip>
	) : null;

	const verificationStatusIcon = isExtensionInstalled ? (
		<Tooltip title={processStatus.message}>
			<IconButton size="small" onClick={goToExtensionAuth}>
				{processStatus.icon}
			</IconButton>
		</Tooltip>
	) : null;

	const resetSteps = useCallback(() => {
		return new Promise((res) => {
			setSteps([]);
			res(steps);
		});
	}, [setSteps, steps]);

	return {
		isExtensionInstalled,
		isExtensionVerified,
		isExtensionAuthenticated,
		isProcessing,
		extensionVersion,
		token,
		steps,
		error,
		sendMessageToExtension,
		goToExtensionAuth,
		showLinkToVerification,
		checkIfExtensionIsInstalled,
		checkIfExtensionIsAuthenticated,
		loginToExtension,
		logoutFromExtension,
		getAuthToken,
		processStatus,
		verificationStatusIcon,
		extensionLoginUserDetails,
		resetSteps,
		setPlannedDelay,
	};
};

export default useBrowser;
