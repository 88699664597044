import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ReactFCC } from '../../../../../interface/react';
import usePayments from '../../../usePayments';
import useProduct from '../../useProduct';
import DownloadLinks from '../../../components/DownloadLinks/DownloadLinks';
import { Subscription } from '../../../interface';
import SubscriptionsCard from '../../../Subscriptions/components/SubscriptionItem/SubscriptionCard';
import ProductPlans from '../ProductPlans/ProductPlans';
import Loading from '../../../../../components/Loading/Loading';
import ProductBuy from '../ProductBuy/ProductBuy';

interface ProductOverviewProps {}

const ProductOverview: ReactFCC<ProductOverviewProps> = () => {
	const { t } = useTranslation();
	const { products } = usePayments();
	const {
		productSlug,
		product,
		setProduct,
		hasDownloadLinks,
		hasSubscribed,
		productPlan,
		purchasedSubscriptions,
		isLoadingSubscriptions,
	} = useProduct();

	const overviewContent = useMemo(() => {
		if (hasSubscribed) {
			return (
				<Box
					maxWidth={!hasSubscribed ? 'sm' : 'lg'}
					sx={{
						mx: 'auto',
						width: '100%',
						display: 'flex',
						flexDirection: 'column',
						alignItems: !hasSubscribed ? 'stretch' : 'center',
					}}
				>
					<Typography
						variant="h6"
						gutterBottom
						sx={{
							fontSize: {
								xs: '1rem',
								sm: '1.25rem',
							},
							mb: {
								xs: 2,
								sm: 3,
							},
						}}
					>
						{t('product_details.my_subscription')}
					</Typography>
					<Box
						sx={{
							// width: '100%',
							display: 'flex',
							flexDirection: 'row',
							gap: 2,
							alignItems: 'stretch',
							flexWrap: 'wrap',
							mb: 2,
						}}
					>
						{purchasedSubscriptions.map((subscription: Subscription) => (
							<SubscriptionsCard key={subscription.id} subscription={subscription} />
						))}
					</Box>
				</Box>
			);
		}

		if (productPlan) {
			return <ProductPlans />;
		}

		return <ProductBuy />;
	}, [t, hasSubscribed, productPlan, purchasedSubscriptions]);

	useEffect(() => {
		if (productSlug && products.length) {
			const currentProduct = products.find((productObj) =>
				[productObj.metadata?.slug, productObj.id].includes(productSlug)
			);
			if (currentProduct) {
				setProduct(currentProduct);
			}
		}
	}, [productSlug, products, setProduct]);

	const attrs = {
		'data-testid': 'products-overview-component',
	};

	if (!product) {
		return null;
	}

	if (isLoadingSubscriptions) {
		return (
			<Box {...attrs}>
				<Box sx={{ width: '100%' }}>
					<Loading />
				</Box>
			</Box>
		);
	}

	return (
		<div {...attrs}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch',
					gap: {
						xs: 2,
						sm: 4,
					},
				}}
			>
				{hasDownloadLinks && (
					<Box>
						<Typography
							variant="button"
							component="h3"
							sx={{ textAlign: 'center', textTransform: 'none' }}
							gutterBottom
						>
							{t('product_details.download')}
						</Typography>
						<DownloadLinks data={product.metadata} />
					</Box>
				)}
				{overviewContent}
			</Box>
		</div>
	);
};

export default ProductOverview;
