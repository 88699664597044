import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	CardMedia,
	Dialog,
	FormControl,
	IconButton,
	LinearProgress,
	NativeSelect,
	Slide,
	Tooltip,
	Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { ReactFCC } from '../../../../../interface/react';
import { ErrorMessage, Product, ProductPrice, Subscription } from '../../../interface';
import { addCheckoutSession, getDocFromPathById } from '../../../service';
import useAuth from '../../../../auth/useAuth';
import usePayments from '../../../usePayments';
import { getFormattedCurrency, intervalText, sortPricings } from '../../../utils';
import PricingBox from '../PricingBox/PricingBox';
import ProductPriceLoader from '../../../components/ProductPricesLoader/ProductPricesLoader';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement;
	},
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

interface ProductCardProps {
	product: Product;
	subscriptions: Subscription[];
}

const ProductCard: ReactFCC<ProductCardProps> = (props) => {
	const { product, subscriptions } = props;
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { user, isAuthenticated } = useAuth();
	const { productPrices } = usePayments();
	const [plan, setPlan] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState<ErrorMessage | null>(null);
	const [showPrices, setShowPrices] = React.useState(false);
	const [showPriceDropdown] = React.useState(false);

	const prices = useMemo(() => {
		return (productPrices[product.id] || []).sort(sortPricings);
	}, [productPrices, product.id]);

	const purchasedSubscription = useMemo(() => {
		return subscriptions.find((subscription) => subscription.product.id === product.id);
	}, [subscriptions, product.id]);

	const hasSubscribed = useMemo(() => {
		return !!purchasedSubscription;
	}, [purchasedSubscription]);

	useEffect(() => {
		setPlan(prices?.[0]?.id);
	}, [prices]);

	const onShowPricing = () => {
		setShowPrices(true);
	};

	const showProductDetails = () => {
		navigate(`/product/${product.metadata?.slug || product.id}`);
	};

	const onBuyNow = async () => {
		if (!isAuthenticated) {
			navigate(`/login?redirect=${window.location.pathname}`);
		}
		setLoading(true);
		setError(null);
		const priceDetails = prices.find((price: ProductPrice) => price.id === plan);
		const selectedPrice: any = {
			price: plan,
		};
		if (priceDetails?.recurring?.usage_type !== 'metered') {
			selectedPrice.quantity = 1;
		}
		const checkoutSession: any = {
			automatic_tax: true,
			tax_id_collection: true,
			collect_shipping_address: true,
			allow_promotion_codes: true,
			line_items: [selectedPrice],
			success_url: window.location.origin,
			cancel_url: window.location.origin,
			metadata: {
				key: 'value',
			},
		};
		// For one time payments set mode to payment.
		if (priceDetails?.type === 'one_time') {
			checkoutSession.mode = 'payment';
			checkoutSession.payment_method_types = ['card'];
		}

		const newDocRef = await addCheckoutSession(user?.uid as string, checkoutSession);
		let url: any = null;
		let count = 0;
		while (!url || count < 3) {
			// eslint-disable-next-line no-await-in-loop
			const newDoc = await getDocFromPathById(newDocRef.parent.path, newDocRef.id);
			if (newDoc.error) {
				console.error(newDoc.error);
				setError(newDoc.error);
				break;
			}
			if (newDoc.url) {
				url = newDoc.url;
				window.location.assign(newDoc.url);
				break;
			}
			count++;
		}
		setLoading(false);
	};

	const handleChange = (event: { target: { value: string } }) => {
		setPlan(event.target.value);
	};

	const actionPriceComponent = !prices.length ? (
		'..'
	) : (
		<>
			<FormControl>
				<NativeSelect
					value={plan}
					onChange={handleChange}
					disabled={loading}
					sx={{
						fontSize: '0.8rem',
					}}
				>
					{prices.map((price: ProductPrice) => {
						return (
							<option key={price.id} value={price.id}>
								{getFormattedCurrency(price)} {intervalText(price, t)} {price.description}
							</option>
						);
					})}
				</NativeSelect>
			</FormControl>
			<Tooltip title={t('products.buy_now')} arrow>
				<IconButton onClick={onBuyNow} disabled={loading}>
					<ShoppingCartCheckoutIcon />
				</IconButton>
			</Tooltip>
		</>
	);

	const actionSubscribedComponent = (
		<>
			<Typography variant="caption" fontWeight={500} color="primary">
				{t('products.subscribed')}
			</Typography>
			<IconButton onClick={showProductDetails} disabled={loading}>
				<ArrowForwardIosIcon color="primary" fontSize="small" />
			</IconButton>
		</>
	);

	const subscribedButton = (
		<Tooltip title={t('products.subscribed')}>
			{/* <IconButton onClick={showProductDetails} disabled={loading}> */}
			<CheckCircleOutlineIcon color="success" fontSize="small" sx={{ m: 1 }} />
			{/* </IconButton> */}
		</Tooltip>
	);

	const showPricingButton = showPriceDropdown ? (
		<Tooltip title={t('common.view')} arrow>
			<IconButton onClick={onShowPricing} disabled={loading}>
				<ReadMoreIcon />
			</IconButton>
		</Tooltip>
	) : null;

	return (
		<Card
			sx={{
				width: 345,
				border: '1px solid #eaeaea',
				borderRadius: '10px',
				transition: 'all 0.2s ease-in',
				'&:hover': {
					borderColor: '#000',
					boxShadow: '0 0 11px rgba(33,33,33,.2)',
				},
				display: 'flex',
				flexDirection: 'column',
				position: 'relative',
			}}
			elevation={0}
		>
			<Box sx={{ position: 'absolute', left: 0, width: '100%', zIndex: 1 }}>
				{loading && <LinearProgress />}
				{error && (
					<Box
						sx={{
							p: 1,
							backgroundColor: 'error.main',
							color: 'error.contrastText',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
						}}
					>
						<Typography variant="caption">{error.message}</Typography>
						<IconButton
							onClick={() => setError(null)}
							sx={{ color: '#fff', border: '1px solid', m: 1, width: 32, height: 32 }}
						>
							<CloseIcon />
						</IconButton>
					</Box>
				)}
			</Box>
			<CardHeader
				avatar={
					<Avatar
						sx={{
							width: 32,
							height: 32,
							backgroundColor: 'transparent',
							cursor: 'pointer',
						}}
						onClick={showProductDetails}
					>
						<CardMedia component="img" image={product.images[0]} alt={product.name} sx={{ width: 32, height: 32 }} />
					</Avatar>
				}
				title={
					<>
						<Typography
							variant="subtitle2"
							onClick={showProductDetails}
							sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}
						>
							{product.name}
							<Tooltip title={t('products.view_details')}>
								<OpenInNewIcon fontSize="inherit" onClick={showProductDetails} />
							</Tooltip>
						</Typography>{' '}
						<ProductPriceLoader product={product}>
							<Typography
								variant="caption"
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: 1,
									fontSize: '0.8rem',
								}}
							>
								{prices.length ? `${prices.length} ${t('products.pricings_plans')}` : t('products.no_price')}
							</Typography>
						</ProductPriceLoader>
					</>
				}
				action={hasSubscribed ? subscribedButton : showPricingButton}
			/>
			<CardContent
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					justifyContent: 'space-between',
					pt: 0,
					'&:last-child': {
						paddingBottom: 2,
					},
				}}
			>
				<Typography variant="caption" color="text.secondary">
					{product.description}
				</Typography>
			</CardContent>
			<CardActions sx={{ display: 'flex', justifyContent: 'flex-end', pt: 0 }}>
				{showPriceDropdown ? (
					<Box
						sx={{
							minWidth: 120,
							width: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							pl: 1,
						}}
					>
						{hasSubscribed ? (
							actionSubscribedComponent
						) : (
							<ProductPriceLoader product={product}>{actionPriceComponent}</ProductPriceLoader>
						)}
					</Box>
				) : (
					<Tooltip title={t('common.view')} arrow>
						<IconButton size="small" color="primary" onClick={showProductDetails} disabled={loading}>
							<ArrowForwardIcon fontSize="inherit" />
						</IconButton>
					</Tooltip>
				)}
			</CardActions>
			<Dialog
				open={showPrices}
				onClose={() => setShowPrices(false)}
				TransitionComponent={Transition}
				sx={{
					backgroundColor: 'transparent',
				}}
			>
				{showPrices && <PricingBox product={product} onClose={() => setShowPrices(false)} />}
			</Dialog>
		</Card>
	);
};

export default ProductCard;
