import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Card, Divider, Typography } from '@mui/material';
import { ReactFCC } from '../../interface/react';
import useAuth from '../auth/useAuth';

interface ProfileProps {}

const Profile: ReactFCC<ProfileProps> = () => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const displayName = user?.displayName || user?.email || 'User';
	const lastLoginDate = new Date(user?.metadata.lastSignInTime as string).toDateString();
	const lastLoginTime = new Date(user?.metadata.lastSignInTime as string).toLocaleTimeString();

	return (
		<div data-testid="profile-component">
			<Card
				sx={{
					maxWidth: 345,
					my: {
						xs: 1,
						md: 3,
					},
					mx: 'auto',
					p: 5,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 1,
					border: '1px solid',
					borderColor: 'divider',
				}}
				elevation={2}
			>
				<Avatar
					alt={displayName}
					src={user?.photoURL || user?.displayName || ''}
					sx={{
						width: 100,
						height: 100,
						mb: 2,
					}}
				/>
				<Typography variant="h5" component="div">
					{displayName}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{user?.email}
				</Typography>
				<Divider sx={{ width: '100%', my: 1 }} />
				<Typography variant="caption" color="text.secondary" fontWeight="600">
					{t('profile.last_login')}
				</Typography>
				<Typography variant="body2" color="text.secondary">
					{lastLoginDate} {lastLoginTime}
				</Typography>
			</Card>
		</div>
	);
};

export default Profile;
