import React from 'react';
import { Typography } from '@mui/material';
import { ReactFCC } from '../../interface/react';
import styles from './PageTitle.module.scss';

interface PageTitleProps {
	title: string;
	sx?: any;
	font?: 'comfortaa' | 'default';
}

const PageTitle: ReactFCC<PageTitleProps> = ({ title, sx, font }) => {
	const classes = font ? styles[`font-${font}`] : styles.default;
	return (
		<Typography
			variant="h4"
			component="h1"
			className={classes}
			sx={{
				fontSize: {
					xs: '1.8rem',
					sm: '2.125rem',
				},
				fontWeight: 'bold',
				...sx,
			}}
		>
			{title}
		</Typography>
	);
};

export default PageTitle;
