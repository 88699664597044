import React from 'react';
import RouteConfig from '../../routes/interface';
import renderRoutes from '../../routes/utils';
import PaymentsHome from './PaymentsHome/PaymentsHome';
import Products from './Products/Products';
import Subscriptions from './Subscriptions/Subscriptions';
import ExtensionAuth from '../ExtensionAuth/ExtensionAuth';

const routes: RouteConfig[] = [
	{
		title: 'Overview',
		path: '/',
		component: <PaymentsHome />,
		exact: true,
		isPublic: true,
	},
	{
		title: 'Products',
		path: '/products',
		component: <Products />,
		exact: true,
		isPublic: true,
	},
	{
		title: 'Subscriptions',
		path: '/subscriptions',
		component: <Subscriptions />,
		exact: true,
		isPublic: false,
	},
	{
		title: 'Extension Auth',
		path: '/verification',
		component: <ExtensionAuth />,
		exact: true,
		isPublic: false,
	},
];

export const Routes = renderRoutes(routes);

export default routes;
