import React from 'react';
import { Avatar, Box } from '@mui/material';
import { ReactFCC } from '../../../../interface/react';
import { ProductMetadata } from '../../interface';
import { downloadLinkItemIcons, downloadLinkItems, downloadLinkLabels } from '../../constants';
import { DOWNLOAD_LINKS } from '../../enum';

interface DownloadLinksProps {
	data: ProductMetadata;
}

const DownloadLinks: ReactFCC<DownloadLinksProps> = ({ data }) => {
	const onDownloadLinkClickHandler = (link: string) => {
		window.open(link, '_blank');
	};

	return (
		<div data-testid="downloadlinks-component">
			<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
				{downloadLinkItems.map((item: DOWNLOAD_LINKS) => {
					if (!data[`link_${item}`]) {
						return null;
					}
					return (
						<Avatar
							key={item}
							alt={downloadLinkLabels[item]}
							sx={{
								width: {
									xs: 24,
									sm: 32,
								},
								height: {
									xs: 24,
									sm: 32,
								},
								cursor: 'pointer',
								transition: 'all 0.2s ease-in-out',
								'&:hover': {
									transform: 'scale(1.1)',
								},
							}}
							onClick={() => onDownloadLinkClickHandler(data[`link_${item}`])}
							src={downloadLinkItemIcons[item]}
						/>
					);
				})}
			</Box>
		</div>
	);
};

export default DownloadLinks;
