import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, LinearProgress, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactFCC } from '../../../interface/react';
import usePayments from '../usePayments';
import ProductCard from './components/ProductCard/ProductCard';
import useAuth from '../../auth/useAuth';

interface ProductsProps {}

const Products: ReactFCC<ProductsProps> = () => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const {
		products,
		subscriptions,
		useRequestProductsRef: { refetch: refetchProducts, isLoading: isLoadingProducts, isFetching: isFetchingProducts },
		useRequestSubscriptionsRef: { refetch: refetchSubscriptions, isLoading: isLoadingSubscriptions },
	} = usePayments();

	const attrs = {
		'data-testid': 'products-component',
	};

	useEffect(() => {
		if (!products.length && !isLoadingProducts && !isFetchingProducts) {
			refetchProducts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (user?.uid && !subscriptions.length && !isLoadingSubscriptions) {
			setTimeout(() => {
				refetchSubscriptions();
			}, 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isLoadingProducts) {
		return (
			<Box {...attrs}>
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			</Box>
		);
	}

	return (
		<Box
			{...attrs}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Box sx={{ width: '100%' }}>{isFetchingProducts && <LinearProgress />}</Box>
				<IconButton onClick={refetchProducts} disabled={isFetchingProducts}>
					<RefreshIcon />
				</IconButton>
			</Box>

			{!products.length ? (
				<Typography
					variant="body1"
					sx={{
						textAlign: 'center',
					}}
				>
					{t('products.no_products')}
				</Typography>
			) : (
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}>
					{products.map((product: any) => (
						<ProductCard key={product.id} product={product} subscriptions={subscriptions} />
					))}
				</Box>
			)}
		</Box>
	);
};

export default Products;
