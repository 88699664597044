import React, { useEffect } from 'react';
import { ReactFCC } from '../../../../interface/react';
import usePayments from '../../usePayments';
import { Product, ProductPrice } from '../../interface';
import { useGetPriceForProduct } from '../../useRequest';
import { PricesObject, ProductPricesObject } from '../../PaymentsProvider';

interface ProductPricesLoaderProps {
	product: Product;
}

const ProductPricesLoader: ReactFCC<ProductPricesLoaderProps> = (props) => {
	const { product, children } = props;
	const { productPrices, setProductPrices, setPrices } = usePayments();

	const useRequestProductPricesRef = useGetPriceForProduct(
		{
			productId: product.id,
		},
		{
			onSuccess: (productPricesRes: ProductPrice[]) => {
				if (productPricesRes.length === 0) return;
				const productId = productPricesRes[0].product;
				setProductPrices((prevProductPrices: ProductPricesObject) => {
					const updatedProductPrices = { ...prevProductPrices, [productId]: productPricesRes };
					return updatedProductPrices;
				});
				setPrices((prevPrices: PricesObject) => {
					const pricesByPriceId = productPricesRes.reduce((acc: any, price: ProductPrice) => {
						return { ...acc, [price.id]: price };
					}, {});
					const updatedPrices = { ...prevPrices, ...pricesByPriceId };
					return updatedPrices;
				});
			},
			onError: (err: any) => {
				console.log(err);
			},
			enabled: false,
		}
	);

	useEffect(() => {
		if (!productPrices[product.id]) {
			useRequestProductPricesRef.refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <span>{useRequestProductPricesRef.isLoading ? '...' : children}</span>;
};

export default ProductPricesLoader;
