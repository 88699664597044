import React, { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, CardHeader, Divider, LinearProgress, Typography } from '@mui/material';
import LoginIcon from '@mui/icons-material/Login';
import DownloadIcon from '@mui/icons-material/Download';
import { ReactFCC } from '../../../../interface/react';
import useAuth from '../../../auth/useAuth';
import { statuses } from './const';
import useBrowser from './useBrowser';
import { DataForExtension } from './interface';

interface VerificationProps {
	browserExtensionId: string;
	mode?: 'icon' | 'link' | 'text' | 'button';
	dataForExtension?: DataForExtension;
}

const wrapperBoxStyles = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
	textAlign: 'center',
	gap: 2,
	// maxWidth: 300,
	mx: 'auto',
};

const Verification: ReactFCC<VerificationProps> = ({ browserExtensionId, mode, dataForExtension }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useAuth();
	const {
		isProcessing,
		steps,
		extensionVersion,
		checkIfExtensionIsInstalled,
		checkIfExtensionIsAuthenticated,
		isExtensionAuthenticated,
		isExtensionInstalled,
		loginToExtension,
		logoutFromExtension,
		getAuthToken,
		verificationStatusIcon,
		extensionLoginUserDetails,
		resetSteps,
	} = useBrowser({
		browserExtensionId,
		delayBetweenProcesses: 1000,
		dataForExtension,
	});

	const startVerification = useCallback(() => {
		resetSteps()
			.then(getAuthToken)
			.then(loginToExtension)
			.then(checkIfExtensionIsAuthenticated)
			.catch((err) => {
				console.log(err);
			});
	}, [resetSteps, getAuthToken, loginToExtension, checkIfExtensionIsAuthenticated]);

	const startAuthenticationCheck = useCallback(() => {
		resetSteps()
			.then(checkIfExtensionIsInstalled)
			.then(checkIfExtensionIsAuthenticated)
			.catch((err) => {
				console.log(err);
			});
	}, [resetSteps, checkIfExtensionIsInstalled, checkIfExtensionIsAuthenticated]);

	const logout = useCallback(() => {
		resetSteps()
			.then(logoutFromExtension)
			.then(checkIfExtensionIsAuthenticated)
			.catch((err) => {
				console.log(err);
			});
	}, [resetSteps, logoutFromExtension, checkIfExtensionIsAuthenticated]);

	const goToLogin = useCallback(() => {
		navigate(`/login?redirect=${window.location.pathname}`);
	}, [navigate]);

	const onInstallExtensionClick = useCallback(() => {
		window.open(`https://chrome.google.com/webstore/detail/${browserExtensionId}`, '_blank');
	}, [browserExtensionId]);

	const actionToShow = useMemo(() => {
		if (!isExtensionInstalled) {
			return (
				<Box sx={{ ...wrapperBoxStyles, alignItems: 'center' }}>
					<Typography variant="h6" fontWeight={500}>
						{t('verification.extension_not_installed')}
					</Typography>
					<Typography
						sx={{
							fontSize: '2rem',
							display: 'flex',
						}}
					>
						{statuses.extension_installation_check_failed.icon}
					</Typography>
					<Divider sx={{ my: 2, alignSelf: 'stretch' }} />
					<Button
						variant="contained"
						size="small"
						sx={{
							textTransform: 'none',
						}}
						startIcon={<DownloadIcon />}
						onClick={onInstallExtensionClick}
					>
						{t('verification.install_extension')}
					</Button>
				</Box>
			);
		}

		if (!user) {
			return (
				<Box sx={{ ...wrapperBoxStyles, alignItems: 'center' }}>
					<Typography variant="h6" fontWeight={500}>
						{t('verification.extension_installed')}
					</Typography>
					<Typography
						sx={{
							fontSize: '2rem',
							display: 'flex',
						}}
					>
						{statuses.extension_installation_check_success.icon}
					</Typography>
					<Divider sx={{ my: 2, alignSelf: 'stretch' }} />
					<Button
						variant="contained"
						size="small"
						sx={{
							textTransform: 'none',
						}}
						endIcon={<LoginIcon />}
						onClick={goToLogin}
					>
						{t('verification.please_login')}
					</Button>
				</Box>
			);
		}

		if (!isExtensionAuthenticated) {
			return (
				<Box sx={{ ...wrapperBoxStyles, alignItems: 'center' }}>
					<Typography variant="h6" fontWeight={500}>
						{t('verification.extension_not_authenticated')}
					</Typography>
					<Typography
						sx={{
							fontSize: '2rem',
							display: 'flex',
						}}
					>
						{statuses.user_check_failed.icon}
					</Typography>
					<Divider sx={{ my: 2, alignSelf: 'stretch' }} />
					<Button
						variant="contained"
						size="small"
						sx={{
							textTransform: 'none',
						}}
						startIcon={<DownloadIcon />}
						onClick={startVerification}
						disabled={isProcessing}
					>
						{t('verification.sign_in_into_extension')}
					</Button>
				</Box>
			);
		}

		if (isExtensionAuthenticated) {
			return (
				<Box sx={{ ...wrapperBoxStyles, alignItems: 'center' }}>
					<Typography variant="h6" fontWeight={500}>
						{t('verification.extension_authenticated')}
					</Typography>
					<Typography
						sx={{
							fontSize: '2rem',
							display: 'flex',
						}}
					>
						{statuses.extension_auth_check_success.icon}
					</Typography>
					<Divider sx={{ my: 2, alignSelf: 'stretch' }} />
					<Button
						variant="contained"
						size="small"
						sx={{
							textTransform: 'none',
						}}
						startIcon={<DownloadIcon />}
						disabled={isProcessing}
						onClick={logout}
					>
						Logout from Extension
					</Button>
					<Box>You are Logged in As</Box>
				</Box>
			);
		}

		return null;
	}, [
		user,
		isExtensionAuthenticated,
		isExtensionInstalled,
		isProcessing,
		t,
		goToLogin,
		startVerification,
		logout,
		onInstallExtensionClick,
	]);

	const generateButton = useCallback(
		(title: string, icon: React.ReactNode, icon2: React.ReactNode, onClick: any, btnTitle: string) => {
			return (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'flex-start',
						gap: 1,
						width: '100%',
					}}
				>
					<Typography
						sx={{
							fontSize: '1.25rem',
							display: 'flex',
						}}
					>
						{icon}
					</Typography>
					<Typography variant="caption" fontWeight={500}>
						{title}
					</Typography>
					<Divider sx={{ my: 2, flex: 1, alignSelf: 'stretch' }} />
					<Button
						variant="outlined"
						size="small"
						sx={{
							textTransform: 'none',
							fontSize: '0.75rem',
						}}
						startIcon={icon2}
						disabled={isProcessing}
						onClick={onClick}
					>
						{btnTitle}
					</Button>
				</Box>
			);
		},
		[isProcessing]
	);

	const actionButtonToShow = useMemo(() => {
		if (isProcessing) {
			return (
				<Typography variant="overline">
					{steps.length && statuses[steps[steps.length - 1]]?.message
						? t(statuses[steps[steps.length - 1]]?.message)
						: ''}
				</Typography>
			);
		}
		if (!isExtensionInstalled) {
			return generateButton(
				t('verification.extension_not_installed'),
				statuses.extension_installation_check_failed.icon,
				<DownloadIcon />,
				onInstallExtensionClick,
				t('verification.install_extension')
			);
		}

		if (!user) {
			return generateButton(
				t('verification.extension_installed'),
				statuses.extension_installation_check_success.icon,
				<LoginIcon />,
				goToLogin,
				t('verification.please_login')
			);
		}

		if (!isExtensionAuthenticated) {
			return generateButton(
				t('verification.extension_not_authenticated'),
				statuses.user_check_failed.icon,
				<DownloadIcon />,
				startVerification,
				t('verification.sign_in_into_extension')
			);
		}

		if (isExtensionAuthenticated) {
			return generateButton(
				t('verification.extension_authenticated'),
				statuses.extension_auth_check_success.icon,
				<DownloadIcon />,
				logout,
				t('Logout from Extension')
			);
		}

		return null;
	}, [
		user,
		isExtensionAuthenticated,
		isExtensionInstalled,
		t,
		goToLogin,
		startVerification,
		logout,
		onInstallExtensionClick,
		generateButton,
		isProcessing,
		steps,
	]);

	useEffect(() => {
		if (mode === 'icon' || mode === 'link') {
			checkIfExtensionIsInstalled();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		startAuthenticationCheck();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (mode === 'icon') {
		return verificationStatusIcon;
	}

	if (mode === 'link') {
		return verificationStatusIcon;
	}

	return (
		<div data-testid="verification-component">
			<Box sx={wrapperBoxStyles}>
				{extensionVersion && (
					<Typography variant="body2" fontWeight={500}>
						{t('verification.extension_version')}: {extensionVersion}
					</Typography>
				)}

				{extensionLoginUserDetails && (
					<>
						<Typography variant="body2">You are Logged in As</Typography>
						<CardHeader
							sx={{
								width: '100%',
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'flex-start',
								gap: 1,
								backgroundColor: 'grey.50',
								border: '1px solid',
								borderColor: 'grey.200',
								borderRadius: 2,
								textAlign: 'left',
							}}
							avatar={
								<Avatar
									alt={extensionLoginUserDetails?.displayName}
									sx={{ width: 48, height: 48 }}
									src={extensionLoginUserDetails?.profileImage}
								/>
							}
							title={<Typography variant="subtitle2">{extensionLoginUserDetails?.displayName}</Typography>}
							subheader={<Typography variant="caption">{extensionLoginUserDetails?.email}</Typography>}
						/>
					</>
				)}
				{mode === 'button' ? actionButtonToShow : actionToShow}
				{/* 
				<Divider sx={{ my: 2 }} />

				<Box>
					{['extension_installation_check', 'user_check', 'extension_auth_check'].map((status: string) => {
						const currentStatus: string =
							[`${status}_success`, `${status}_failed`, `${status}`].find((s: string) => {
								return steps.includes(s);
							}) || '';
						if (!currentStatus) return null;
						const statusToShow = statuses[currentStatus];
						return (
							<Box
								key={`one_${status}`}
								sx={{
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									gap: 2,
								}}
							>
								<Typography variant="body2" component="span">
									{t(`verification.${status}`)}
								</Typography>
								<Tooltip title={statusToShow.message} arrow placement="left">
									<Box>{statusToShow.icon}</Box>
								</Tooltip>
							</Box>
						);
					})}
				</Box> */}
				{/* <Box>
					{steps.map((step: string) => (
						<div key={step}>
							{step} {statuses[step] && <IconButton size="small">{statuses[step].icon}</IconButton>}
						</div>
					))}
				</Box> */}
				{isProcessing && (
					<Box sx={{ width: '100%' }}>
						<LinearProgress />
					</Box>
				)}
			</Box>
		</div>
	);
};

export default Verification;
