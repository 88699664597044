import React from 'react';
import { Box, MenuItem, Select, Typography } from '@mui/material';
import { ObjectWithStringValues, ReactFCC } from '../../interface/react';
import i18n from '../../i18n';
import useGlobal from '../../features/Global/useGlobal';
import {
	countryCodeToLanguageShort,
	languageCodeFullToCountryCode,
	languageShortToLanguageText,
} from '../../common/countryAndLanguages';
import { countryCodeEmoji } from '../../common/countryCodeEmoji';

interface LanguageSwitcherProps {}

const langShortToCountryCode: ObjectWithStringValues = {
	en: 'US',
	es: 'ES',
	id: 'ID',
};

const LanguageSwitcher: ReactFCC<LanguageSwitcherProps> = () => {
	const { changeLanguage, options } = i18n;
	const { language, setLanguage } = useGlobal();

	const handleChange = (event: any) => {
		changeLanguage(event.target.value as string);
		setLanguage(event.target.value as string);
	};

	const languages = Object.keys(options.resources || {}) || [];

	if (languages.length <= 1) {
		return null;
	}

	return (
		<Select
			value={language}
			onChange={handleChange}
			size="small"
			sx={{
				fontSize: '0.8rem',
				'.MuiSelect-select': {
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				},
				'.ls-label': {
					display: {
						xs: 'none',
						sm: 'flex',
					},
				},
			}}
		>
			{languages.map((lang: string) => {
				const countryCode = langShortToCountryCode[lang] || languageCodeFullToCountryCode[lang]; // from the country codes I have specified
				return (
					<MenuItem
						key={lang}
						value={lang}
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Typography sx={{ fontSize: 20, lineHeight: '24px' }} component="span">
							{countryCode && countryCodeEmoji(countryCode)}
						</Typography>
						<Box className="ls-label" sx={{ ml: 1.5 }}>
							{languageShortToLanguageText[countryCodeToLanguageShort[countryCode].toLocaleLowerCase()]}
						</Box>
					</MenuItem>
				);
			})}
		</Select>
	);
};

export default LanguageSwitcher;
