import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactFCC } from '../../interface/react';
import styles from './Logo.module.scss';

interface LogoProps {}

const Logo: ReactFCC<LogoProps> = () => {
	const { t } = useTranslation();

	return (
		<div className={styles.logo} data-testid="logo-component">
			010Pixel {t('logo.payments')}
		</div>
	);
};

export default Logo;
