import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	Divider,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import { ReactFCC } from '../../interface/react';
import { pages, settings } from '../../shared/menu';
import useAuth from '../../features/auth/useAuth';
import ProfileBox from '../ProfileBox/ProfileBox';

interface ProfileDropdownMenuProps {}

const ProfileDropdownMenu: ReactFCC<ProfileDropdownMenuProps> = () => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const navigate = useNavigate();
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};
	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const onMenuItemClickHandler = (path: string) => {
		navigate(path);
		handleCloseUserMenu();
	};

	return (
		<>
			<IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
				<Avatar
					sx={{
						width: 32,
						height: 32,
					}}
					alt={user?.displayName || user?.email || 'User'}
					src={user?.photoURL || user?.displayName || ''}
				/>
			</IconButton>

			<Menu
				sx={{ mt: '45px' }}
				id="menu-appbar"
				anchorEl={anchorElUser}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				keepMounted
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				open={Boolean(anchorElUser)}
				onClose={handleCloseUserMenu}
			>
				<Card
					elevation={0}
					sx={{
						minWidth: 280,
						my: -1,
						border: '1px solid',
						borderColor: 'divider',
					}}
				>
					<ProfileBox onIconClick={handleCloseUserMenu} />

					<Divider />

					<CardContent
						sx={{
							p: 0,
							'&:last-child': {
								paddingBottom: 1,
							},
						}}
					>
						<Box sx={{ py: 1, flexGrow: 0, flexDirection: 'column' }}>
							{pages.map((page) => {
								const PageIcon = page.icon;
								return (
									<MenuItem key={page.path} onClick={() => onMenuItemClickHandler(page.path)}>
										<ListItemIcon sx={{ color: 'success' }}>
											<IconButton disableRipple sx={{ width: 24, height: 24 }}>
												<PageIcon sx={{ width: 20, height: 20 }} />
											</IconButton>
										</ListItemIcon>
										<ListItemText>
											<Typography variant="subtitle2">{t(`menus.${page.title}`)}</Typography>
										</ListItemText>
									</MenuItem>
								);
							})}
						</Box>

						<Divider />

						<Box sx={{ py: 1, flexGrow: 0, flexDirection: 'column' }}>
							{settings.map((setting) => {
								const SettingIcon = setting.icon;
								return (
									<MenuItem key={setting.path} onClick={() => onMenuItemClickHandler(setting.path)}>
										<ListItemIcon sx={{ color: 'success' }}>
											<IconButton disableRipple sx={{ width: 24, height: 24 }}>
												<SettingIcon sx={{ width: 20, height: 20 }} />
											</IconButton>
										</ListItemIcon>
										<ListItemText>
											<Typography variant="subtitle2">{t(`menus.${setting.title}`)}</Typography>
										</ListItemText>
									</MenuItem>
								);
							})}
						</Box>
					</CardContent>
				</Card>
			</Menu>
		</>
	);
};

export default ProfileDropdownMenu;
