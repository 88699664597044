import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Card, CardActions, CardMedia, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import SettingsIcon from '@mui/icons-material/Settings';
import { ReactFCC } from '../../../../../interface/react';
import { Product, ProductPrice, Subscription } from '../../../interface';
import usePayments from '../../../usePayments';
import { getFormattedCurrency, intervalTextShort } from '../../../utils';
import { SUBSCRIPTION_STATUS } from '../../../enum';
import CustomerPortal from '../CustomerPortal/CustomerPortal';

interface SubscriptionsCardProps {
	subscription: Subscription;
}

const SubscriptionsCard: ReactFCC<SubscriptionsCardProps> = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { subscription } = props;
	const { products, prices } = usePayments();
	const priceId = subscription.price.id;
	const priceData = (subscription?.items?.[0]?.price || prices[priceId] || {}) as ProductPrice;
	const productId = subscription.product.id;
	const productData = (subscription?.items?.[0]?.price.product ||
		products.find((product) => product.id === productId) ||
		{}) as Product;

	const getStatusIcon = useCallback((title: string, icon: any) => {
		const IconComponent = icon;
		return (
			<Tooltip title={title} arrow>
				<IconButton
					disableRipple
					size="small"
					sx={{
						color: 'limegreen',
					}}
				>
					<IconComponent fontSize="inherit" />
				</IconButton>
			</Tooltip>
		);
	}, []);
	const activeStatus = useMemo(() => {
		if (subscription.status === SUBSCRIPTION_STATUS.TRIALING) {
			return getStatusIcon(t('subscriptions.status_trial'), CheckCircleOutlineIcon);
		}
		if (subscription.status === SUBSCRIPTION_STATUS.ACTIVE) {
			return getStatusIcon(t('subscriptions.status_active'), CheckCircleOutlineIcon);
		}
		return null;
	}, [subscription, t, getStatusIcon]);

	const getExpiryWarning = useCallback((title: string, timestampSeconds: number, textColor: string, icon: any) => {
		const IconComponent = icon;
		return (
			<Tooltip
				title={title}
				arrow
				sx={{
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Typography
					variant="caption"
					component="span"
					sx={{
						fontWeight: '500',
						display: 'flex',
						alignItems: 'center',
						color: textColor,
						userSelect: 'none',
					}}
				>
					<IconButton disableRipple size="small" color="inherit" sx={{ cursor: 'initial' }}>
						<IconComponent fontSize="inherit" />
					</IconButton>
					{new Date(timestampSeconds * 1000).toDateString().split(' ').slice(1).join(' ')}
				</Typography>
			</Tooltip>
		);
	}, []);

	const periodEndWarning = useMemo(() => {
		if (subscription.cancel_at_period_end) {
			return getExpiryWarning(
				t('subscriptions.subscription_expiry_date'),
				subscription.cancel_at.seconds,
				'warning.main',
				AccessAlarmIcon
			);
		}
		if (subscription.trial_end) {
			return getExpiryWarning(
				t('subscriptions.trial_end_date'),
				subscription.trial_end.seconds,
				'warning.light',
				AccessTimeFilledIcon
			);
		}
		return '';
	}, [subscription, t, getExpiryWarning]);

	const gridContent = useMemo(() => {
		const gridContentValue = [];
		gridContentValue.push({
			label: t('subscriptions.auto_renew'),
			value: (
				<Box sx={{ display: 'flex' }}>
					{subscription.cancel_at_period_end ? (
						<CustomerPortal
							component={
								<Tooltip title={t('subscriptions.turn_on')} arrow>
									<Box sx={{ display: 'flex' }}>
										<ToggleOffIcon sx={{ color: 'error.light', cursor: 'pointer' }} />
									</Box>
								</Tooltip>
							}
							fallback={
								<Box sx={{ display: 'flex' }}>
									<ToggleOffIcon sx={{ color: 'error.light', cursor: 'pointer' }} />
								</Box>
							}
						/>
					) : (
						<CustomerPortal
							component={
								<Tooltip title={t('subscriptions.turn_off')} arrow>
									<Box sx={{ display: 'flex' }}>
										<ToggleOnIcon sx={{ color: 'limegreen', cursor: 'pointer' }} />
									</Box>
								</Tooltip>
							}
							fallback={
								<Box sx={{ display: 'flex' }}>
									<ToggleOnIcon sx={{ color: 'limegreen', cursor: 'pointer' }} />
								</Box>
							}
						/>
					)}
				</Box>
			),
		});

		if (subscription.canceled_at) {
			gridContentValue.push({
				label: t('subscriptions.cancelled_on'),
				value: new Date(subscription.canceled_at.seconds * 1000).toLocaleString(),
			});
			gridContentValue.push({
				label: t('subscriptions.last_day'),
				value: new Date(subscription.cancel_at.seconds * 1000).toLocaleString(),
			});
		}

		if (subscription.trial_start) {
			gridContentValue.push({
				label: t('subscriptions.trial_starts_on'),
				value: new Date(subscription.trial_start.seconds * 1000).toLocaleString(),
			});
		}
		if (subscription.trial_end) {
			gridContentValue.push({
				label: t('subscriptions.trial_ends_on'),
				value: new Date(subscription.trial_end.seconds * 1000).toLocaleString(),
			});
		}
		return gridContentValue;
	}, [subscription, t]);

	const subscriptionTitle = useMemo(() => {
		if (subscription.status === SUBSCRIPTION_STATUS.ACTIVE) {
			return t('subscriptions.subscription_period');
		}
		if (subscription.status === SUBSCRIPTION_STATUS.TRIALING) {
			return t('subscriptions.trial_period');
		}
		return '';
	}, [subscription, t]);

	const subscriptionStatusChip = useMemo(() => {
		if (subscription.status === SUBSCRIPTION_STATUS.ACTIVE) {
			return (
				<Chip
					label={t('subscriptions.status_active')}
					size="small"
					sx={{ backgroundColor: 'success.main', color: 'white' }}
				/>
			);
		}
		if (subscription.status === SUBSCRIPTION_STATUS.TRIALING) {
			return (
				<Chip
					label={t('subscriptions.status_trial')}
					size="small"
					sx={{ backgroundColor: 'warning.main', color: 'white' }}
				/>
			);
		}
		if (subscription.status === SUBSCRIPTION_STATUS.CANCELED) {
			return (
				<Chip
					label={t('subscriptions.status_cancelled')}
					size="small"
					sx={{ backgroundColor: 'error.main', color: 'white' }}
				/>
			);
		}
		if (subscription.status === SUBSCRIPTION_STATUS.PAUSED) {
			return (
				<Chip
					label={t('subscriptions.status_paused')}
					size="small"
					sx={{ backgroundColor: 'primary.main', color: 'white' }}
				/>
			);
		}
		return '';
	}, [subscription, t]);

	const showProductDetails = () => {
		navigate(`/product/${productData.metadata?.slug || productData.id}`);
	};

	return (
		<Card
			key={subscription.id}
			sx={{
				width: 345,
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',

				borderRadius: 3,
				transition: 'all 0.2s ease-in',
				'&:hover': {
					boxShadow:
						'0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
				},
				position: 'relative',
				border: subscription.cancel_at ? '1px solid' : '',
				borderColor: subscription.cancel_at ? 'warning.light' : '',
			}}
			elevation={2}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
					gap: 2,
					p: 3,
					pb: 1,
					backgroundColor: subscription.cancel_at ? 'primary.contrastText' : 'transparent',
				}}
			>
				<Avatar
					sx={{
						width: 64,
						height: 64,
						backgroundColor: 'transparent',
						cursor: 'pointer',
					}}
					onClick={showProductDetails}
				>
					<CardMedia component="img" image={productData.images[0]} alt={productData.name} />
				</Avatar>

				<Typography
					variant="h6"
					component="h3"
					fontWeight="500"
					onClick={showProductDetails}
					sx={{ cursor: 'pointer' }}
				>
					{productData.name}
				</Typography>

				<Box>
					<Typography variant="caption" component="h4">
						{subscriptionStatusChip}
					</Typography>
				</Box>

				<Box>
					{Object.keys(priceData).length > 0 ? (
						<>
							<div />
							<Typography
								variant="button"
								component="div"
								sx={{
									textTransform: 'none',
									fontSize: '1.8rem',
								}}
							>
								<b>{getFormattedCurrency(priceData)}</b>
							</Typography>
							<Typography
								variant="button"
								component="div"
								sx={{
									textTransform: 'none',
									fontSize: '1rem',
								}}
							>
								{intervalTextShort(priceData, t)} ({priceData.currency.toLocaleUpperCase()})
							</Typography>
						</>
					) : (
						'...'
					)}
				</Box>

				<Box>
					<Typography variant="caption" component="h4" fontWeight={500}>
						{subscriptionTitle}
					</Typography>
					<Typography variant="caption" component="div" color="text.secondary">
						{new Date(subscription.current_period_start.seconds * 1000).toDateString()} -{' '}
						{new Date(subscription.current_period_end.seconds * 1000).toDateString()}
					</Typography>
				</Box>

				{subscription.role === 'premium' && (
					<Box>
						<Typography
							variant="caption"
							component="div"
							color="text.secondary"
							sx={{
								display: 'flex',
								flexDirection: 'row',
								gap: 0.5,
								justifyContent: 'center',
							}}
						>
							<>
								<WorkspacePremiumIcon color="primary" />
								<Box sx={{ lineHeight: '1.8rem' }}>{t(`subscriptions.role_${subscription.role}`)}</Box>
							</>
						</Typography>
					</Box>
				)}

				{gridContent.length > 0 && (
					<Box
						sx={{
							display: 'grid',
							gridTemplateColumns: '1fr 1.5fr',
							textAlign: 'left',
							alignItems: 'center',
							gap: 1,
						}}
					>
						{gridContent.map((item) => (
							<React.Fragment key={item.label}>
								<Typography variant="caption" component="div">
									{item.label}
								</Typography>
								<Typography
									variant="caption"
									component="div"
									color="text.secondary"
									sx={{
										textTransform: 'capitalize',
									}}
								>
									{item.value}
								</Typography>
							</React.Fragment>
						))}
					</Box>
				)}
			</Box>
			<CardActions
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyItems: 'center',
					justifyContent: 'center',
					borderTop: '1px solid',
					borderColor: 'divider',
					px: 2,
				}}
			>
				<Typography variant="caption">{t('subscriptions.status')}</Typography>
				<Box sx={{ display: 'flex' }}>{activeStatus}</Box>
				<Box sx={{ flexGrow: 1 }} />
				<Box sx={{ display: 'flex' }}>{periodEndWarning}</Box>
				<Box>
					<CustomerPortal
						component={
							<Tooltip title={t('subscriptions.manage_subscription')} arrow>
								<IconButton
									disableRipple
									size="small"
									sx={{
										color: 'primary.contractText',
									}}
								>
									<SettingsIcon fontSize="inherit" />
								</IconButton>
							</Tooltip>
						}
					/>
				</Box>
			</CardActions>
		</Card>
	);
};

export default SubscriptionsCard;
