import { Plans } from './interface';

const youtubeSkipAdTriggerPlans: Plans = {
	free: {
		title: 'free',
		features: ['Skip Ad Automatically after Countdown', 'Close Ad-Banners', 'Opt-out of Analytics'],
		requireSubscription: false,
	},
	premium: {
		title: 'premium',
		features: [
			'Everything in Free',
			'Skip Ads even before the countdown starts',
			'Skip Ads after 30 seconds',
			'Skip Ads after 60 seconds',
		],
		requireSubscription: true,
	},
};

const easyMuteForGoogleMeetPlans = {
	free: {
		title: 'free',
		features: ['Toggle Mute from any tab', 'Opt-out of Analytics'],
		requireSubscription: false,
	},
	premium: {
		title: 'premium',
		features: [
			'Everything in Free',
			'Ability to show Number of calls on badge',
			'Show Number of All Calls on badge',
			'Show Number of only Muted Calls on badge',
			'Show Number of only Unmuted Calls on badge',
			'Mute Microphone by default when call starts',
			'Turn off Camera by default when call starts',
			'Press the Spacebar shortcut to toggle the Microphone/Camera on or off.',
		],
		requireSubscription: true,
	},
};

export const plans: { [productName: string]: Plans } = {
	'youtube-video-skip-ad-trigger': youtubeSkipAdTriggerPlans,
	'easy-mute-for-google-meet': easyMuteForGoogleMeetPlans,
};

export default { plans };
