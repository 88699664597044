export enum DOWNLOAD_LINKS {
	'chrome' = 'chrome',
	'firefox' = 'firefox',
	'edge' = 'edge',
}

export enum BILLING_TYPE {
	'one_time' = 'one_time',
	'recurring' = 'recurring',
}

export enum STRIPE_OBJECT_NAME {
	PLAN = 'plan',
	PRICE = 'price',
	PRODUCT = 'product',
	TAX_RATE = 'tax_rate',
}

export enum SUBSCRIPTION_STATUS {
	ACTIVE = 'active',
	PAST_DUE = 'past_due',
	UNPAID = 'unpaid',
	CANCELED = 'canceled',
	INCOMPLETE = 'incomplete',
	INCOMPLETE_EXPIRED = 'incomplete_expired',
	TRIALING = 'trialing',
	PAUSED = 'paused',
}

export enum BILLING_SCHEME {
	PER_UNIT = 'per_unit',
}

export enum INTERVAL {
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month',
	YEAR = 'year',
}

export enum USAGE_TYPE {
	LICENSED = 'licensed',
	METERED = 'metered',
}

export enum COLLECTION_METHOD {
	CHARGE_AUTOMATICALLY = 'charge_automatically',
}

export enum ON_OR_OFF {
	ON = 'on',
	OFF = 'off',
}

export enum MISSING_PAYMENT_METHOD {
	CANCEL = 'cancel',
	PAUSE = 'pause',
	CREATE_INVOICE = 'create_invoice',
}

export enum TAX_BEHAVIOR {
	UNSPECIFIED = 'unspecified',
}

export enum TAX_TYPE {
	SALES_TAX = 'sales_tax',
	VAT = 'vat',
}
