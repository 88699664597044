import { ObjectWithStringValues } from '../interface/react';

export const languagesCodeShort: string[] = [
	'FA',
	'SQ',
	'AR',
	'CA',
	'PT',
	'ES',
	'HY',
	'EN',
	'DE',
	'AZ',
	'BN',
	'BE',
	'NL',
	'FR',
	'BS',
	'BG',
	'RN',
	'KM',
	'ZH',
	'HR',
	'EL',
	'CS',
	'DA',
	'ET',
	'AM',
	'FI',
	'KA',
	'HT',
	'HU',
	'IS',
	'HI',
	'ID',
	'HE',
	'IT',
	'JA',
	'KK',
	'SW',
	'KY',
	'LO',
	'LV',
	'ST',
	'LT',
	'LB',
	'MG',
	'NY',
	'MS',
	'BM',
	'MT',
	'RO',
	'MN',
	'SR',
	'MY',
	'NE',
	'HA',
	'KO',
	'NB',
	'UR',
	'PL',
	'RU',
	'RW',
	'WO',
	'SK',
	'SL',
	'SO',
	'SI',
	'SV',
	'TG',
	'TH',
	'TR',
	'TK',
	'UK',
	'UZ',
	'VI',
];

export const languageCodeFull: string[] = [
	'fa-AF',
	'sq-AL',
	'ar-DZ',
	'ca-AD',
	'pt-AO',
	'es-AR',
	'hy-AM',
	'en-AU',
	'de-AT',
	'az-AZ',
	'ar-BH',
	'bn-BD',
	'be-BY',
	'nl-BE',
	'fr-BJ',
	'es-BO',
	'bs-BA',
	'en-BW',
	'pt-BR',
	'bg-BG',
	'fr-BF',
	'rn-BI',
	'km-KH',
	'fr-CM',
	'en-CA',
	'fr-TD',
	'es-CL',
	'zh-CN',
	'es-CO',
	'es-CR',
	'hr-HR',
	'es-CU',
	'el-CY',
	'cs-CZ',
	'da-DK',
	'fr-DJ',
	'es-DO',
	'es-EC',
	'ar-EG',
	'es-SV',
	'et-EE',
	'am-ET',
	'fi-FI',
	'fr-FR',
	'fr-GA',
	'ka-GE',
	'de-DE',
	'en-GH',
	'el-GR',
	'es-GT',
	'fr-GN',
	'ht-HT',
	'es-HN',
	'zh-HK',
	'hu-HU',
	'is-IS',
	'hi-IN',
	'id-ID',
	'fa-IR',
	'ar-IQ',
	'en-IE',
	'he-IL',
	'it-IT',
	'fr-CI',
	'en-JM',
	'ja-JP',
	'ar-JO',
	'kk-KZ',
	'sw-KE',
	'ar-KW',
	'ky-KG',
	'lo-LA',
	'lv-LV',
	'ar-LB',
	'st-LS',
	'en-LR',
	'ar-LY',
	'lt-LT',
	'lb-LU',
	'mg-MG',
	'ny-MW',
	'ms-MY',
	'bm-ML',
	'mt-MT',
	'ar-MR',
	'es-MX',
	'ro-MD',
	'mn-MN',
	'sr-ME',
	'ar-MA',
	'pt-MZ',
	'my-MM',
	'en-NA',
	'ne-NP',
	'nl-NL',
	'en-NZ',
	'es-NI',
	'ha-NE',
	'en-NG',
	'ko-KP',
	'nb-NO',
	'ar-OM',
	'ur-PK',
	'es-PA',
	'en-PG',
	'es-PY',
	'es-PE',
	'en-PH',
	'pl-PL',
	'pt-PT',
	'ar-QA',
	'ro-RO',
	'ru-RU',
	'rw-RW',
	'ar-SA',
	'wo-SN',
	'sr-RS',
	'en-SL',
	'en-SG',
	'sk-SK',
	'sl-SI',
	'so-SO',
	'en-ZA',
	'ko-KR',
	'es-ES',
	'si-LK',
	'ar-SD',
	'sv-SE',
	'de-CH',
	'ar-SY',
	'zh-TW',
	'tg-TJ',
	'sw-TZ',
	'th-TH',
	'fr-TG',
	'en-TT',
	'ar-TN',
	'tr-TR',
	'tk-TM',
	'sw-UG',
	'uk-UA',
	'ar-AE',
	'en-GB',
	'en-US',
	'es-UY',
	'uz-UZ',
	'es-VE',
	'vi-VN',
	'ar-YE',
	'en-ZM',
	'en-ZW',
];

export const languageCodeFullToLanguageCodeShort: ObjectWithStringValues = {
	'fa-AF': 'FA',
	'sq-AL': 'SQ',
	'ar-DZ': 'AR',
	'ca-AD': 'CA',
	'pt-AO': 'PT',
	'es-AR': 'ES',
	'hy-AM': 'HY',
	'en-AU': 'EN',
	'de-AT': 'DE',
	'az-AZ': 'AZ',
	'ar-BH': 'AR',
	'bn-BD': 'BN',
	'be-BY': 'BE',
	'nl-BE': 'NL',
	'fr-BJ': 'FR',
	'es-BO': 'ES',
	'bs-BA': 'BS',
	'en-BW': 'EN',
	'pt-BR': 'PT',
	'bg-BG': 'BG',
	'fr-BF': 'FR',
	'rn-BI': 'RN',
	'km-KH': 'KM',
	'fr-CM': 'FR',
	'en-CA': 'EN',
	'fr-TD': 'FR',
	'es-CL': 'ES',
	'zh-CN': 'ZH',
	'es-CO': 'ES',
	'es-CR': 'ES',
	'hr-HR': 'HR',
	'es-CU': 'ES',
	'el-CY': 'EL',
	'cs-CZ': 'CS',
	'da-DK': 'DA',
	'fr-DJ': 'FR',
	'es-DO': 'ES',
	'es-EC': 'ES',
	'ar-EG': 'AR',
	'es-SV': 'ES',
	'et-EE': 'ET',
	'am-ET': 'AM',
	'fi-FI': 'FI',
	'fr-FR': 'FR',
	'fr-GA': 'FR',
	'ka-GE': 'KA',
	'de-DE': 'DE',
	'en-GH': 'EN',
	'el-GR': 'EL',
	'es-GT': 'ES',
	'fr-GN': 'FR',
	'ht-HT': 'HT',
	'es-HN': 'ES',
	'zh-HK': 'ZH',
	'hu-HU': 'HU',
	'is-IS': 'IS',
	'hi-IN': 'HI',
	'id-ID': 'ID',
	'fa-IR': 'FA',
	'ar-IQ': 'AR',
	'en-IE': 'EN',
	'he-IL': 'HE',
	'it-IT': 'IT',
	'fr-CI': 'FR',
	'en-JM': 'EN',
	'ja-JP': 'JA',
	'ar-JO': 'AR',
	'kk-KZ': 'KK',
	'sw-KE': 'SW',
	'ar-KW': 'AR',
	'ky-KG': 'KY',
	'lo-LA': 'LO',
	'lv-LV': 'LV',
	'ar-LB': 'AR',
	'st-LS': 'ST',
	'en-LR': 'EN',
	'ar-LY': 'AR',
	'lt-LT': 'LT',
	'lb-LU': 'LB',
	'mg-MG': 'MG',
	'ny-MW': 'NY',
	'ms-MY': 'MS',
	'bm-ML': 'BM',
	'mt-MT': 'MT',
	'ar-MR': 'AR',
	'es-MX': 'ES',
	'ro-MD': 'RO',
	'mn-MN': 'MN',
	'sr-ME': 'SR',
	'ar-MA': 'AR',
	'pt-MZ': 'PT',
	'my-MM': 'MY',
	'en-NA': 'EN',
	'ne-NP': 'NE',
	'nl-NL': 'NL',
	'en-NZ': 'EN',
	'es-NI': 'ES',
	'ha-NE': 'HA',
	'en-NG': 'EN',
	'ko-KP': 'KO',
	'nb-NO': 'NB',
	'ar-OM': 'AR',
	'ur-PK': 'UR',
	'es-PA': 'ES',
	'en-PG': 'EN',
	'es-PY': 'ES',
	'es-PE': 'ES',
	'en-PH': 'EN',
	'pl-PL': 'PL',
	'pt-PT': 'PT',
	'ar-QA': 'AR',
	'ro-RO': 'RO',
	'ru-RU': 'RU',
	'rw-RW': 'RW',
	'ar-SA': 'AR',
	'wo-SN': 'WO',
	'sr-RS': 'SR',
	'en-SL': 'EN',
	'en-SG': 'EN',
	'sk-SK': 'SK',
	'sl-SI': 'SL',
	'so-SO': 'SO',
	'en-ZA': 'EN',
	'ko-KR': 'KO',
	'es-ES': 'ES',
	'si-LK': 'SI',
	'ar-SD': 'AR',
	'sv-SE': 'SV',
	'de-CH': 'DE',
	'ar-SY': 'AR',
	'zh-TW': 'ZH',
	'tg-TJ': 'TG',
	'sw-TZ': 'SW',
	'th-TH': 'TH',
	'fr-TG': 'FR',
	'en-TT': 'EN',
	'ar-TN': 'AR',
	'tr-TR': 'TR',
	'tk-TM': 'TK',
	'sw-UG': 'SW',
	'uk-UA': 'UK',
	'ar-AE': 'AR',
	'en-GB': 'EN',
	'en-US': 'EN',
	'es-UY': 'ES',
	'uz-UZ': 'UZ',
	'es-VE': 'ES',
	'vi-VN': 'VI',
	'ar-YE': 'AR',
	'en-ZM': 'EN',
	'en-ZW': 'EN',
};

export const languageCodeFullToCountryCode: ObjectWithStringValues = {
	'fa-AF': 'AF',
	'sq-AL': 'AL',
	'ar-DZ': 'DZ',
	'ca-AD': 'AD',
	'pt-AO': 'AO',
	'es-AR': 'AR',
	'hy-AM': 'AM',
	'en-AU': 'AU',
	'de-AT': 'AT',
	'az-AZ': 'AZ',
	'ar-BH': 'BH',
	'bn-BD': 'BD',
	'be-BY': 'BY',
	'nl-BE': 'BE',
	'fr-BJ': 'BJ',
	'es-BO': 'BO',
	'bs-BA': 'BA',
	'en-BW': 'BW',
	'pt-BR': 'BR',
	'bg-BG': 'BG',
	'fr-BF': 'BF',
	'rn-BI': 'BI',
	'km-KH': 'KH',
	'fr-CM': 'CM',
	'en-CA': 'CA',
	'fr-TD': 'TD',
	'es-CL': 'CL',
	'zh-CN': 'CN',
	'es-CO': 'CO',
	'es-CR': 'CR',
	'hr-HR': 'HR',
	'es-CU': 'CU',
	'el-CY': 'CY',
	'cs-CZ': 'CZ',
	'da-DK': 'DK',
	'fr-DJ': 'DJ',
	'es-DO': 'DO',
	'es-EC': 'EC',
	'ar-EG': 'EG',
	'es-SV': 'SV',
	'et-EE': 'EE',
	'am-ET': 'ET',
	'fi-FI': 'FI',
	'fr-FR': 'FR',
	'fr-GA': 'GA',
	'ka-GE': 'GE',
	'de-DE': 'DE',
	'en-GH': 'GH',
	'el-GR': 'GR',
	'es-GT': 'GT',
	'fr-GN': 'GN',
	'ht-HT': 'HT',
	'es-HN': 'HN',
	'zh-HK': 'HK',
	'hu-HU': 'HU',
	'is-IS': 'IS',
	'hi-IN': 'IN',
	'id-ID': 'ID',
	'fa-IR': 'IR',
	'ar-IQ': 'IQ',
	'en-IE': 'IE',
	'he-IL': 'IL',
	'it-IT': 'IT',
	'fr-CI': 'CI',
	'en-JM': 'JM',
	'ja-JP': 'JP',
	'ar-JO': 'JO',
	'kk-KZ': 'KZ',
	'sw-KE': 'KE',
	'ar-KW': 'KW',
	'ky-KG': 'KG',
	'lo-LA': 'LA',
	'lv-LV': 'LV',
	'ar-LB': 'LB',
	'st-LS': 'LS',
	'en-LR': 'LR',
	'ar-LY': 'LY',
	'lt-LT': 'LT',
	'lb-LU': 'LU',
	'mg-MG': 'MG',
	'ny-MW': 'MW',
	'ms-MY': 'MY',
	'bm-ML': 'ML',
	'mt-MT': 'MT',
	'ar-MR': 'MR',
	'es-MX': 'MX',
	'ro-MD': 'MD',
	'mn-MN': 'MN',
	'sr-ME': 'ME',
	'ar-MA': 'MA',
	'pt-MZ': 'MZ',
	'my-MM': 'MM',
	'en-NA': 'NA',
	'ne-NP': 'NP',
	'nl-NL': 'NL',
	'en-NZ': 'NZ',
	'es-NI': 'NI',
	'ha-NE': 'NE',
	'en-NG': 'NG',
	'ko-KP': 'KP',
	'nb-NO': 'NO',
	'ar-OM': 'OM',
	'ur-PK': 'PK',
	'es-PA': 'PA',
	'en-PG': 'PG',
	'es-PY': 'PY',
	'es-PE': 'PE',
	'en-PH': 'PH',
	'pl-PL': 'PL',
	'pt-PT': 'PT',
	'ar-QA': 'QA',
	'ro-RO': 'RO',
	'ru-RU': 'RU',
	'rw-RW': 'RW',
	'ar-SA': 'SA',
	'wo-SN': 'SN',
	'sr-RS': 'RS',
	'en-SL': 'SL',
	'en-SG': 'SG',
	'sk-SK': 'SK',
	'sl-SI': 'SI',
	'so-SO': 'SO',
	'en-ZA': 'ZA',
	'ko-KR': 'KR',
	'es-ES': 'ES',
	'si-LK': 'LK',
	'ar-SD': 'SD',
	'sv-SE': 'SE',
	'de-CH': 'CH',
	'ar-SY': 'SY',
	'zh-TW': 'TW',
	'tg-TJ': 'TJ',
	'sw-TZ': 'TZ',
	'th-TH': 'TH',
	'fr-TG': 'TG',
	'en-TT': 'TT',
	'ar-TN': 'TN',
	'tr-TR': 'TR',
	'tk-TM': 'TM',
	'sw-UG': 'UG',
	'uk-UA': 'UA',
	'ar-AE': 'AE',
	'en-GB': 'GB',
	'en-US': 'US',
	'es-UY': 'UY',
	'uz-UZ': 'UZ',
	'es-VE': 'VE',
	'vi-VN': 'VN',
	'ar-YE': 'YE',
	'en-ZM': 'ZM',
	'en-ZW': 'ZW',
};

export const countryCodeToLanguageShort: ObjectWithStringValues = {
	AF: 'FA',
	AL: 'SQ',
	DZ: 'AR',
	AD: 'CA',
	AO: 'PT',
	AR: 'ES',
	AM: 'HY',
	AU: 'EN',
	AT: 'DE',
	AZ: 'AZ',
	BH: 'AR',
	BD: 'BN',
	BY: 'BE',
	BE: 'NL',
	BJ: 'FR',
	BO: 'ES',
	BA: 'BS',
	BW: 'EN',
	BR: 'PT',
	BG: 'BG',
	BF: 'FR',
	BI: 'RN',
	KH: 'KM',
	CM: 'FR',
	CA: 'EN',
	TD: 'FR',
	CL: 'ES',
	CN: 'ZH',
	CO: 'ES',
	CR: 'ES',
	HR: 'HR',
	CU: 'ES',
	CY: 'EL',
	CZ: 'CS',
	DK: 'DA',
	DJ: 'FR',
	DO: 'ES',
	EC: 'ES',
	EG: 'AR',
	SV: 'ES',
	EE: 'ET',
	ET: 'AM',
	FI: 'FI',
	FR: 'FR',
	GA: 'FR',
	GE: 'KA',
	DE: 'DE',
	GH: 'EN',
	GR: 'EL',
	GT: 'ES',
	GN: 'FR',
	HT: 'HT',
	HN: 'ES',
	HK: 'ZH',
	HU: 'HU',
	IS: 'IS',
	IN: 'HI',
	ID: 'ID',
	IR: 'FA',
	IQ: 'AR',
	IE: 'EN',
	IL: 'HE',
	IT: 'IT',
	CI: 'FR',
	JM: 'EN',
	JP: 'JA',
	JO: 'AR',
	KZ: 'KK',
	KE: 'SW',
	KW: 'AR',
	KG: 'KY',
	LA: 'LO',
	LV: 'LV',
	LB: 'AR',
	LS: 'ST',
	LR: 'EN',
	LY: 'AR',
	LT: 'LT',
	LU: 'LB',
	MG: 'MG',
	MW: 'NY',
	MY: 'MS',
	ML: 'BM',
	MT: 'MT',
	MR: 'AR',
	MX: 'ES',
	MD: 'RO',
	MN: 'MN',
	ME: 'SR',
	MA: 'AR',
	MZ: 'PT',
	MM: 'MY',
	NA: 'EN',
	NP: 'NE',
	NL: 'NL',
	NZ: 'EN',
	NI: 'ES',
	NE: 'HA',
	NG: 'EN',
	KP: 'KO',
	NO: 'NB',
	OM: 'AR',
	PK: 'UR',
	PA: 'ES',
	PG: 'EN',
	PY: 'ES',
	PE: 'ES',
	PH: 'EN',
	PL: 'PL',
	PT: 'PT',
	QA: 'AR',
	RO: 'RO',
	RU: 'RU',
	RW: 'RW',
	SA: 'AR',
	SN: 'WO',
	RS: 'SR',
	SL: 'EN',
	SG: 'EN',
	SK: 'SK',
	SI: 'SL',
	SO: 'SO',
	ZA: 'EN',
	KR: 'KO',
	ES: 'ES',
	LK: 'SI',
	SD: 'AR',
	SE: 'SV',
	CH: 'DE',
	SY: 'AR',
	TW: 'ZH',
	TJ: 'TG',
	TZ: 'SW',
	TH: 'TH',
	TG: 'FR',
	TT: 'EN',
	TN: 'AR',
	TR: 'TR',
	TM: 'TK',
	UG: 'SW',
	UA: 'UK',
	AE: 'AR',
	GB: 'EN',
	US: 'EN',
	UY: 'ES',
	UZ: 'UZ',
	VE: 'ES',
	VN: 'VI',
	YE: 'AR',
	ZM: 'EN',
	ZW: 'EN',
};

export const countryCodeToLanguageLong: ObjectWithStringValues = {
	AF: 'fa-AF',
	AL: 'sq-AL',
	DZ: 'ar-DZ',
	AD: 'ca-AD',
	AO: 'pt-AO',
	AR: 'es-AR',
	AM: 'hy-AM',
	AU: 'en-AU',
	AT: 'de-AT',
	AZ: 'az-AZ',
	BH: 'ar-BH',
	BD: 'bn-BD',
	BY: 'be-BY',
	BE: 'nl-BE',
	BJ: 'fr-BJ',
	BO: 'es-BO',
	BA: 'bs-BA',
	BW: 'en-BW',
	BR: 'pt-BR',
	BG: 'bg-BG',
	BF: 'fr-BF',
	BI: 'rn-BI',
	KH: 'km-KH',
	CM: 'fr-CM',
	CA: 'en-CA',
	TD: 'fr-TD',
	CL: 'es-CL',
	CN: 'zh-CN',
	CO: 'es-CO',
	CR: 'es-CR',
	HR: 'hr-HR',
	CU: 'es-CU',
	CY: 'el-CY',
	CZ: 'cs-CZ',
	DK: 'da-DK',
	DJ: 'fr-DJ',
	DO: 'es-DO',
	EC: 'es-EC',
	EG: 'ar-EG',
	SV: 'es-SV',
	EE: 'et-EE',
	ET: 'am-ET',
	FI: 'fi-FI',
	FR: 'fr-FR',
	GA: 'fr-GA',
	GE: 'ka-GE',
	DE: 'de-DE',
	GH: 'en-GH',
	GR: 'el-GR',
	GT: 'es-GT',
	GN: 'fr-GN',
	HT: 'ht-HT',
	HN: 'es-HN',
	HK: 'zh-HK',
	HU: 'hu-HU',
	IS: 'is-IS',
	IN: 'hi-IN',
	ID: 'id-ID',
	IR: 'fa-IR',
	IQ: 'ar-IQ',
	IE: 'en-IE',
	IL: 'he-IL',
	IT: 'it-IT',
	CI: 'fr-CI',
	JM: 'en-JM',
	JP: 'ja-JP',
	JO: 'ar-JO',
	KZ: 'kk-KZ',
	KE: 'sw-KE',
	KW: 'ar-KW',
	KG: 'ky-KG',
	LA: 'lo-LA',
	LV: 'lv-LV',
	LB: 'ar-LB',
	LS: 'st-LS',
	LR: 'en-LR',
	LY: 'ar-LY',
	LT: 'lt-LT',
	LU: 'lb-LU',
	MG: 'mg-MG',
	MW: 'ny-MW',
	MY: 'ms-MY',
	ML: 'bm-ML',
	MT: 'mt-MT',
	MR: 'ar-MR',
	MX: 'es-MX',
	MD: 'ro-MD',
	MN: 'mn-MN',
	ME: 'sr-ME',
	MA: 'ar-MA',
	MZ: 'pt-MZ',
	MM: 'my-MM',
	NA: 'en-NA',
	NP: 'ne-NP',
	NL: 'nl-NL',
	NZ: 'en-NZ',
	NI: 'es-NI',
	NE: 'ha-NE',
	NG: 'en-NG',
	KP: 'ko-KP',
	NO: 'nb-NO',
	OM: 'ar-OM',
	PK: 'ur-PK',
	PA: 'es-PA',
	PG: 'en-PG',
	PY: 'es-PY',
	PE: 'es-PE',
	PH: 'en-PH',
	PL: 'pl-PL',
	PT: 'pt-PT',
	QA: 'ar-QA',
	RO: 'ro-RO',
	RU: 'ru-RU',
	RW: 'rw-RW',
	SA: 'ar-SA',
	SN: 'wo-SN',
	RS: 'sr-RS',
	SL: 'en-SL',
	SG: 'en-SG',
	SK: 'sk-SK',
	SI: 'sl-SI',
	SO: 'so-SO',
	ZA: 'en-ZA',
	KR: 'ko-KR',
	ES: 'es-ES',
	LK: 'si-LK',
	SD: 'ar-SD',
	SE: 'sv-SE',
	CH: 'de-CH',
	SY: 'ar-SY',
	TW: 'zh-TW',
	TJ: 'tg-TJ',
	TZ: 'sw-TZ',
	TH: 'th-TH',
	TG: 'fr-TG',
	TT: 'en-TT',
	TN: 'ar-TN',
	TR: 'tr-TR',
	TM: 'tk-TM',
	UG: 'sw-UG',
	UA: 'uk-UA',
	AE: 'ar-AE',
	GB: 'en-GB',
	US: 'en-US',
	UY: 'es-UY',
	UZ: 'uz-UZ',
	VE: 'es-VE',
	VN: 'vi-VN',
	YE: 'ar-YE',
	ZM: 'en-ZM',
	ZW: 'en-ZW',
};

export const languageShortToLanguageText: ObjectWithStringValues = {
	af: 'Afrikaans',
	am: 'አማርኛ',
	an: 'Aragonés',
	ar: 'العربية',
	as: 'অসমীয়া',
	az: 'Azərbaycanca',
	be: 'Беларуская',
	bg: 'Български',
	bn: 'বাঙালি',
	br: 'Brezhoneg',
	bs: 'Bosanski',
	ca: 'Català',
	cs: 'Čeština',
	cy: 'Cymraeg',
	da: 'Dansk',
	de: 'Deutsch',
	el: 'Ελληνικά',
	en: 'English',
	eo: 'Esperanto',
	es: 'Español',
	et: 'Eesti',
	eu: 'Euskara',
	fa: 'فارسی',
	fi: 'Suomi',
	fr: 'Français',
	ga: 'Gaeilge',
	gd: 'Gàidhlig',
	gl: 'Galego',
	gu: 'ગુજરાતી',
	he: 'עברית',
	hi: 'हिन्दी',
	hr: 'Hrvatski',
	hu: 'Magyar',
	hy: 'Հայերեն',
	id: 'Bahasa Indonesia',
	is: 'Íslenska',
	it: 'Italiano',
	ja: '日本語',
	jv: 'Basa Jawa',
	ka: 'ქართული',
	kk: 'Қазақша',
	kn: 'ಕನ್ನಡ',
	ko: '한국어',
	ku: 'Kurdî',
	ky: 'Кыргызча',
	la: 'Latina',
	lb: 'Lëtzebuergesch',
	lt: 'Lietuvių',
	lv: 'Latviešu',
	mg: 'Malagasy',
	mk: 'Македонски',
	ml: 'മലയാളം',
	mr: 'मराठी',
	ms: 'Bahasa Melayu',
	mt: 'Malti',
	nb: 'Norsk (Bokmål)',
	ne: 'नेपाली',
	nl: 'Nederlands',
	nn: 'Norsk (Nynorsk)',
	oc: 'Occitan',
	pa: 'ਪੰਜਾਬੀ',
	pl: 'Polski',
	ps: 'پښتو',
	pt: 'Português',
	ro: 'Română',
	ru: 'Русский',
	si: 'සිංහල',
	sk: 'Slovenčina',
	sl: 'Slovenščina',
	sq: 'Shqip',
	sr: 'Српски',
	sv: 'Svenska',
	sw: 'Kiswahili',
	ta: 'தமிழ்',
	te: 'తెలుగు',
	th: 'ไทย',
	tr: 'Türkçe',
	uk: 'Українська',
	ur: 'اردو',
	vi: 'Tiếng Việt',
	zh: '中文',
};

export const countryCodeToCountryName: ObjectWithStringValues = {
	AF: 'Afghanistan',
	AL: 'Albania',
	DZ: 'Algeria',
	AD: 'Andorra',
	AO: 'Angola',
	AR: 'Argentina',
	AM: 'Armenia',
	AU: 'Australia',
	AT: 'Austria',
	AZ: 'Azerbaijan',
	BH: 'Bahrain',
	BD: 'Bangladesh',
	BY: 'Belarus',
	BE: 'Belgium',
	BJ: 'Benin',
	BO: 'Bolivia',
	BA: 'Bosnia and Herzegovina',
	BW: 'Botswana',
	BR: 'Brazil',
	BG: 'Bulgaria',
	BF: 'Burkina Faso',
	BI: 'Burundi',
	KH: 'Cambodia',
	CM: 'Cameroon',
	CA: 'Canada',
	TD: 'Chad',
	CL: 'Chile',
	CN: 'China',
	CO: 'Colombia',
	CR: 'Costa Rica',
	HR: 'Croatia',
	CU: 'Cuba',
	CY: 'Cyprus',
	CZ: 'Czech Republic',
	DK: 'Denmark',
	DJ: 'Djibouti',
	DO: 'Dominican Republic',
	EC: 'Ecuador',
	EG: 'Egypt',
	SV: 'El Salvador',
	EE: 'Estonia',
	ET: 'Ethiopia',
	FI: 'Finland',
	FR: 'France',
	GA: 'Gabon',
	GE: 'Georgia',
	DE: 'Germany',
	GH: 'Ghana',
	GR: 'Greece',
	GT: 'Guatemala',
	GN: 'Guinea',
	HT: 'Haiti',
	HN: 'Honduras',
	HK: 'Hong Kong',
	HU: 'Hungary',
	IS: 'Iceland',
	IN: 'India',
	ID: 'Indonesia',
	IR: 'Iran',
	IQ: 'Iraq',
	IE: 'Ireland',
	IL: 'Israel',
	IT: 'Italy',
	CI: 'Ivory Coast',
	JM: 'Jamaica',
	JP: 'Japan',
	JO: 'Jordan',
	KZ: 'Kazakhstan',
	KE: 'Kenya',
	KW: 'Kuwait',
	KG: 'Kyrgyzstan',
	LA: 'Laos',
	LV: 'Latvia',
	LB: 'Lebanon',
	LS: 'Lesotho',
	LR: 'Liberia',
	LY: 'Libya',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	MG: 'Madagascar',
	MW: 'Malawi',
	MY: 'Malaysia',
	ML: 'Mali',
	MT: 'Malta',
	MR: 'Mauritania',
	MX: 'Mexico',
	MD: 'Moldova',
	MN: 'Mongolia',
	ME: 'Montenegro',
	MA: 'Morocco',
	MZ: 'Mozambique',
	MM: 'Myanmar',
	NA: 'Namibia',
	NP: 'Nepal',
	NL: 'Netherlands',
	NZ: 'New Zealand',
	NI: 'Nicaragua',
	NE: 'Niger',
	NG: 'Nigeria',
	KP: 'North Korea',
	NO: 'Norway',
	OM: 'Oman',
	PK: 'Pakistan',
	PA: 'Panama',
	PG: 'Papua New Guinea',
	PY: 'Paraguay',
	PE: 'Peru',
	PH: 'Philippines',
	PL: 'Poland',
	PT: 'Portugal',
	QA: 'Qatar',
	RO: 'Romania',
	RU: 'Russia',
	RW: 'Rwanda',
	SA: 'Saudi Arabia',
	SN: 'Senegal',
	RS: 'Serbia',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapore',
	SK: 'Slovakia',
	SI: 'Slovenia',
	SO: 'Somalia',
	ZA: 'South Africa',
	KR: 'South Korea',
	ES: 'Spain',
	LK: 'Sri Lanka',
	SD: 'Sudan',
	SE: 'Sweden',
	CH: 'Switzerland',
	SY: 'Syria',
	TW: 'Taiwan',
	TJ: 'Tajikistan',
	TZ: 'Tanzania',
	TH: 'Thailand',
	TG: 'Togo',
	TT: 'Trinidad and Tobago',
	TN: 'Tunisia',
	TR: 'Turkey',
	TM: 'Turkmenistan',
	UG: 'Uganda',
	UA: 'Ukraine',
	AE: 'United Arab Emirates',
	GB: 'United Kingdom',
	US: 'United States',
	UY: 'Uruguay',
	UZ: 'Uzbekistan',
	VE: 'Venezuela',
	VN: 'Vietnam',
	YE: 'Yemen',
	ZM: 'Zambia',
	ZW: 'Zimbabwe',
};

export const getCountryCodeFromLanguageShort = (lang: string) => {
	const countryId = Object.entries(countryCodeToLanguageShort).find(
		// eslint-disable-next-line
		([countryCode, langId]) => langId.toLowerCase() === lang.toLowerCase()
	);
	return countryId?.[0] || '';
};

export const getCountryCodeFromLanguageFull = (lang: string) => {
	const countryId = Object.entries(countryCodeToLanguageLong).find(
		// eslint-disable-next-line
		([countryCode, langId]) => langId.toLowerCase() === lang.toLowerCase()
	);
	return countryId?.[0] || '';
};
