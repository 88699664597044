import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en.json';
import es from './locales/es.json';
import id from './locales/id.json';

const resources = {
	en: {
		translation: en,
	},
	es: {
		translation: es,
	},
	id: {
		translation: id,
	},
};

const options = {
	order: ['querystring', 'localStorage', 'navigator'],
	lookupQuerystring: 'lng',
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		// the translations
		// (tip move them in a JSON file and import them,
		// or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
		resources,
		// lng: 'en', // if you're using a language detector, do not define the lng option
		detection: options,
		fallbackLng: 'en',

		interpolation: {
			escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		},
	});

export default i18n;
