import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ReactFCC } from '../../../../../interface/react';
import usePayments from '../../../usePayments';

interface CustomerPortalProps {
	component?: React.ReactNode;
	fallback?: React.ReactNode;
}
const CustomerPortal: ReactFCC<CustomerPortalProps> = (props) => {
	const { component, fallback } = props;
	const { t } = useTranslation();
	const { getBillingPortalLinkRef, customerPortalUrl, subscriptions } = usePayments();

	const gotoCustomerPortal = useCallback(() => {
		if (customerPortalUrl) {
			window.open(customerPortalUrl, '_blank');
		}
	}, [customerPortalUrl]);

	useEffect(() => {
		if (subscriptions.length) {
			getBillingPortalLinkRef?.refech?.();
		}
	}, [subscriptions, getBillingPortalLinkRef]);

	const content = component ? (
		<Box onClick={gotoCustomerPortal} component="span">
			{component}
		</Box>
	) : (
		<>
			<Divider sx={{ width: '100%' }} />
			<Button
				variant="outlined"
				onClick={gotoCustomerPortal}
				endIcon={<ArrowForwardIcon />}
				sx={{
					textTransform: 'none',
				}}
			>
				{t('customer_portal.manage_my_subscriptions')}
			</Button>
		</>
	);

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				gap: 2,
			}}
		>
			{customerPortalUrl ? content : fallback}
		</Box>
	);
};

export default CustomerPortal;
