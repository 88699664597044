import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, LinearProgress, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ReactFCC } from '../../../interface/react';
import { Subscription } from '../interface';
import usePayments from '../usePayments';
import CustomerPortal from './components/CustomerPortal/CustomerPortal';
import SubscriptionsCard from './components/SubscriptionItem/SubscriptionCard';

interface SubscriptionsProps {}

const Subscriptions: ReactFCC<SubscriptionsProps> = () => {
	const { t } = useTranslation();
	const {
		subscriptions,
		useRequestSubscriptionsRef: {
			refetch: refetchSubscriptions,
			isLoading: isLoadingSubscriptions,
			isFetching: isFetchingSubscriptions,
		},
		useRequestProductsRef: { refetch: refetchProducts, isLoading: isLoadingProducts, isFetching: isFetchingProducts },
	} = usePayments();

	const isLoadingData = isLoadingSubscriptions || isLoadingProducts;
	const isFetchingData = isFetchingSubscriptions || isFetchingProducts;

	const refetch = useCallback(() => {
		setTimeout(() => {
			refetchSubscriptions();
			refetchProducts();
		}, 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!subscriptions.length && !isLoadingSubscriptions && !isFetchingSubscriptions) {
			refetch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const attrs = {
		'data-testid': 'subscriptions-component',
	};

	if (isLoadingData) {
		return (
			<Box {...attrs}>
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			</Box>
		);
	}

	return (
		<Box
			{...attrs}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				gap: 2,
			}}
		>
			<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
				<Box sx={{ width: '100%' }}>{isFetchingData && <LinearProgress />}</Box>
				<IconButton onClick={refetch} disabled={isFetchingProducts}>
					<RefreshIcon />
				</IconButton>
			</Box>

			{!subscriptions.length ? (
				<Typography
					variant="body1"
					sx={{
						textAlign: 'center',
					}}
				>
					{t('subscriptions.no_subscriptions')}
				</Typography>
			) : (
				<Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap', mb: 2 }}>
					{subscriptions.map((subscription: Subscription) => (
						<SubscriptionsCard key={subscription.id} subscription={subscription} />
					))}
				</Box>
			)}

			<CustomerPortal />
		</Box>
	);
};

export default Subscriptions;
