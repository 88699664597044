import { ObjectWithStringValues } from '../interface/react';

const firebaseProjectAlias = (process.env.REACT_APP_FIREBASE_PROJECT_ALIAS || 'DEFAULT').toLocaleUpperCase();

const envVariables = [
	'GOOGLE_ANALYTICS_ID',
	'FIREBASE_SIGN_IN_OPTIONS',
	'FIREBASE_CUSTOMER_PORTAL_CLOUD_FUNC_NAME',
	'FIREBASE_API_KEY',
	'FIREBASE_AUTH_DOMAIN',
	'FIREBASE_DATABASE_URL',
	'FIREBASE_PROJECT_ID',
	'FIREBASE_STORAGE_BUCKET',
	'FIREBASE_SENDER_ID',
	'FIREBASE_APP_ID',
	'WEBSITE_URL',
].reduce((acc: ObjectWithStringValues, cur: string) => {
	acc[cur] = process.env[`REACT_APP_${firebaseProjectAlias}_${cur}`] || (process.env[`REACT_APP_${cur}`] as string);
	return acc;
}, {});

const config: ObjectWithStringValues = {
	firebaseProjectAlias,
	...envVariables,
};

export default config;
