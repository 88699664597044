import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Product } from '../interface';
import usePayments from '../usePayments';
import { PAYMENTS_PATH } from '../constants';
import { plans } from './compoents/ProductPlans/constants';

const useProduct = () => {
	const navigate = useNavigate();
	const { productSlug } = useParams();
	const [product, setProduct] = useState<Product | null>(null);
	const {
		products,
		subscriptions,
		useRequestProductsRef: { isLoading: isLoadingProducts },
		useRequestSubscriptionsRef: { isLoading: isLoadingSubscriptions },
	} = usePayments();
	const productPlan = useMemo(() => plans[productSlug || ''], [productSlug]);

	const hasDownloadLinks = useMemo(() => {
		if (!product || !product.metadata) {
			return false;
		}
		return Object.keys(product.metadata).some((key) => key.includes('link_'));
	}, [product]);

	const purchasedSubscriptions = useMemo(() => {
		return subscriptions.filter((subscription) => subscription.product.id === product?.id);
	}, [subscriptions, product?.id]);

	const hasSubscribed = useMemo(() => {
		return !!purchasedSubscriptions.length;
	}, [purchasedSubscriptions]);

	const goBackToProducts = () => {
		navigate(`${PAYMENTS_PATH}/products`);
	};

	useEffect(() => {
		if (productSlug && products.length) {
			const currentProduct = products.find((productObj) =>
				[productObj.metadata?.slug, productObj.id].includes(productSlug)
			);
			if (currentProduct) {
				setProduct(currentProduct);
			}
		}
	}, [productSlug, products]);

	return {
		productSlug,
		product,
		setProduct,
		hasDownloadLinks,
		purchasedSubscriptions,
		hasSubscribed,
		productPlan,
		goBackToProducts,
		isLoadingProducts,
		isLoadingSubscriptions,
	};
};

export default useProduct;
