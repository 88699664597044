import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { ReactFCC } from '../../interface/react';
import renderRoutes from '../../routes/utils';
import routes from './routes';
import { PAYMENTS_PATH, paymentTabsLabels } from './constants';
import PageTitle from '../../components/PageTitle/PageTitle';

interface PaymentsProps {}

const Payments: ReactFCC<PaymentsProps> = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [activeTab, setActiveTab] = useState(paymentTabsLabels[0]);

	const RenderedRoutes = renderRoutes(routes);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		navigate(`${PAYMENTS_PATH}/${newValue}`);
	};

	useEffect(() => {
		const currentTab = pathname.split('/')[2]?.toLowerCase();
		if (paymentTabsLabels.includes(currentTab) && currentTab !== activeTab) {
			setActiveTab(currentTab);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	return (
		<div data-testid="payments-component">
			<PageTitle title={t(`payments.title`)} />
			<Box sx={{ borderBottom: 1, borderColor: 'divider', my: 2 }}>
				<Tabs value={activeTab} onChange={handleChange} aria-label={`${t(`payments.title`)}`} variant="scrollable">
					{paymentTabsLabels.map((label) => (
						<Tab
							key={label}
							label={<Typography variant="subtitle2">{t(`${label}.title`)}</Typography>}
							value={label}
							disableRipple
							sx={{
								textTransform: 'capitalize',
							}}
						/>
					))}
				</Tabs>
			</Box>
			<Paper sx={{ my: 0, p: 2 }}>
				<RenderedRoutes />
			</Paper>
		</div>
	);
};

export default Payments;
