import { TFunction } from 'i18next';
import { Product, ProductPrice, Subscription, SubscriptionDataInBrowserExtensionStorage } from './interface';
import { BILLING_TYPE } from './enum';

export const intervalText = (priceObj: ProductPrice, t: TFunction) => {
	const currency = priceObj.currency.toUpperCase();
	const hasMoreThanOneInterval = priceObj.interval_count && priceObj.interval_count > 1;
	const intervalCount = hasMoreThanOneInterval ? priceObj.interval_count : '';
	const pluralText = hasMoreThanOneInterval ? 's' : '';
	const perOrEvery = intervalCount ? t('common.interval.every') : t('common.interval.per');
	const intervalPerText = priceObj.type === BILLING_TYPE.recurring ? perOrEvery : '';
	const intervalPeriodName = priceObj.interval ? t(`common.interval.period.${priceObj.interval}`) : '';
	const recurringOrOneTime =
		priceObj.type === BILLING_TYPE.recurring ? t('common.interval.recurring') : t('common.interval.one_time');
	return `${currency} ${intervalPerText} ${intervalCount} ${intervalPeriodName}${pluralText} ${recurringOrOneTime}`;
};

export const intervalTextShort = (priceObj: ProductPrice, t: TFunction) => {
	const hasMoreThanOneInterval = priceObj.interval_count && priceObj.interval_count > 1;
	const intervalCount = hasMoreThanOneInterval ? priceObj.interval_count : '';
	const pluralText = hasMoreThanOneInterval ? 's' : '';
	const perOrEvery = '/';
	const intervalPerText = priceObj.type === BILLING_TYPE.recurring ? perOrEvery : '';
	const intervalPeriodName = priceObj.interval ? t(`common.interval.period.${priceObj.interval}`) : '';
	const recurringOrOneTime =
		priceObj.type === BILLING_TYPE.recurring ? t('common.interval.recurring') : t('common.interval.one_time');
	return `${intervalPerText} ${intervalCount} ${intervalPeriodName}${pluralText} ${recurringOrOneTime}`;
};

export const getFormattedCurrency = (priceObj: ProductPrice) => {
	return new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: priceObj.currency,
	}).format(priceObj.unit_amount / 100);
};

export const copyContent = async (text: string) => {
	try {
		await navigator.clipboard.writeText(text);
		console.log('Content copied to clipboard');
	} catch (err) {
		console.error('Failed to copy: ', err);
	}
};

export const sortPricings = (a: ProductPrice, b: ProductPrice) => {
	if (a.unit_amount > b.unit_amount) {
		return 1;
	}
	if (a.unit_amount < b.unit_amount) {
		return -1;
	}
	return 0;
};

export const cleanupDataForExtension = (productData: Product, subscription: Subscription) => {
	const subscriptionItem = subscription.items?.[0];
	const price: ProductPrice = (subscriptionItem?.price || {}) as ProductPrice;
	console.log('price', price);
	const subscribedProductData: Product = (subscriptionItem?.price.product || {}) as Product;
	const returnData = {
		product: productData,
		subscription: {
			id: subscription?.id,
			cancel_at: subscription?.cancel_at,
			cancel_at_period_end: subscription?.cancel_at_period_end,
			canceled_at: subscription?.canceled_at,
			current_period_end: subscription?.current_period_end,
			current_period_start: subscription?.current_period_start,
			created: subscription?.created,
			ended_at: subscription?.ended_at,
			role: subscription?.role,
			status: subscription?.status,
			trial_end: subscription?.trial_end,
			trial_start: subscription?.trial_start,
			price: {
				currency: price?.currency,
				unit_amount: price?.unit_amount,
				unit_amount_decimal: price?.unit_amount_decimal,
				interval: price?.interval,
				interval_count: price?.interval_count,
				type: price?.type,
				recurring: price?.recurring || {},
			},
			product: {
				id: subscribedProductData.id,
				name: subscribedProductData.name,
				description: subscribedProductData.description,
				images: subscribedProductData.images,
				metadata: {
					slug: subscribedProductData.metadata?.slug,
				},
			},
		},
	} as SubscriptionDataInBrowserExtensionStorage;
	return returnData;
};
