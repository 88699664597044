import React, { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { ReactFCC } from '../../interface/react';
import i18n from '../../i18n';
import { ThemeType } from './interface';

interface GlobalProviderContext {
	theme: ThemeType;
	setTheme: Dispatch<SetStateAction<ThemeType>>;
	language: string;
	setLanguage: Dispatch<SetStateAction<string>>;
}

const defaultValues = {} as GlobalProviderContext;

export const GlobalContext = createContext<GlobalProviderContext>(defaultValues);

interface GlobalProviderProps {}

const DARK_SCHEME_QUERY = '(prefers-color-scheme: dark)';

export const GlobalProvider: ReactFCC<GlobalProviderProps> = ({ children }) => {
	const isDarkOS = useMediaQuery(DARK_SCHEME_QUERY);
	const userSystemTheme = isDarkOS ? ThemeType.DARK : ThemeType.LIGHT;
	const currentTheme = localStorage.getItem('mode') || userSystemTheme;
	const [theme, setTheme] = useState<ThemeType>(currentTheme as ThemeType);
	const [language, setLanguage] = useState<string>(i18n.language);

	useEffect(() => {
		document
			.querySelector("meta[name='theme-color']")
			?.setAttribute('content', theme === ThemeType.DARK ? '#000000' : '#ffffff');
	}, [theme]);

	const contextValue = React.useMemo(
		() => ({
			theme,
			setTheme,
			language,
			setLanguage,
		}),
		[theme, setTheme, language, setLanguage]
	);

	return <GlobalContext.Provider value={contextValue}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;
