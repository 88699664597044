import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Button, LinearProgress, Typography } from '@mui/material';
import { ReactFCC } from '../../../interface/react';
import useAuth from '../../auth/useAuth';
import usePayments from '../usePayments';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { Routes as RenderedRoutes } from './routes';
import useProduct from './useProduct';

interface ProductDetailsProps {}

const ProductDetails: ReactFCC<ProductDetailsProps> = () => {
	const { t } = useTranslation();
	const { user } = useAuth();
	const {
		products,
		subscriptions,
		useRequestProductsRef: { refetch: refetchProducts, isLoading: isLoadingProducts, isFetching: isFetchingProducts },
		useRequestSubscriptionsRef: {
			refetch: refetchSubscriptions,
			isLoading: isLoadingSubscriptions,
			isFetching: isFetchingSubscriptions,
		},
	} = usePayments();
	const { productSlug, product, setProduct, goBackToProducts } = useProduct();

	const attrs = {
		'data-testid': 'products-details-component',
	};

	useEffect(() => {
		if (productSlug && products.length) {
			const currentProduct = products.find((productObj) =>
				[productObj.metadata?.slug, productObj.id].includes(productSlug)
			);
			if (currentProduct) {
				setProduct(currentProduct);
			}
		}
	}, [productSlug, products, setProduct]);

	useEffect(() => {
		if (!products.length && !isLoadingProducts && !isFetchingProducts) {
			refetchProducts();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (user?.uid && !subscriptions.length && !isLoadingSubscriptions && !isFetchingSubscriptions) {
			setTimeout(() => {
				refetchSubscriptions();
			}, 0);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	if (isLoadingProducts) {
		return (
			<Box {...attrs}>
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
			</Box>
		);
	}

	return (
		<div {...attrs}>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'stretch',
					gap: {
						xs: 2,
						sm: 4,
					},
				}}
			>
				{product ? (
					<>
						<Avatar
							sx={{
								width: 64,
								height: 64,
								alignSelf: 'center',
							}}
							src={product?.images?.[0] || product.name}
							alt={product.name}
						/>
						<Box sx={{ textAlign: 'center' }}>
							<PageTitle title={product.name} />
							{product.description && (
								<Typography variant="body2" component="p" sx={{ mt: 3 }}>
									{product.description}
								</Typography>
							)}
						</Box>
						<RenderedRoutes />
					</>
				) : (
					<Box sx={{ textAlign: 'center' }}>
						<Typography variant="h4" component="h2">
							{t('product_details.no_product_found')}
						</Typography>
					</Box>
				)}
				<Box sx={{ textAlign: 'center' }}>
					<Button size="small" variant="outlined" onClick={goBackToProducts}>
						{t('product_details.view_all_products')}
					</Button>
				</Box>
			</Box>
		</div>
	);
};

export default ProductDetails;
