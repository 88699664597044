import React from 'react';
import { Box } from '@mui/material';
import { ObjectWithStringValues, ReactFCC } from '../../../../interface/react';

declare global {
	namespace JSX {
		interface IntrinsicElements {
			'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
		}
	}
}

interface StripePriceTableProps {
	pricingTableId: string;
	publishableKey: string;
	clientReferenceId?: string;
	customerEmail?: string;
}

const StripePriceTable: ReactFCC<StripePriceTableProps> = (props) => {
	const attrNames: ObjectWithStringValues = {
		pricingTableId: 'pricing-table-id',
		publishableKey: 'publishable-key',
		clientReferenceId: 'client-reference-id',
		customerEmail: 'customer-email',
	};

	const attrs = Object.keys(attrNames).reduce((acc, key) => {
		const attrName = attrNames[key];
		const attrValue = props[key as keyof StripePriceTableProps];
		return {
			...acc,
			[attrName]: attrValue,
		};
	}, {});

	return (
		<Box>
			<stripe-pricing-table {...attrs} />
		</Box>
	);
};

export default StripePriceTable;
