import React from 'react';
import { Box } from '@mui/material';
import { ReactFCC } from '../interface/react';
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

interface EmptyPageLayoutProps {}

const EmptyPageLayout: ReactFCC<EmptyPageLayoutProps> = ({ children }) => {
	return (
		<main>
			<Box
				sx={{
					pt: { xs: 7, sm: 8 },
				}}
			>
				<Header />
				{children}
				<Footer />
			</Box>
		</main>
	);
};

export default EmptyPageLayout;
