import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Container, Paper, Typography } from '@mui/material';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ReactFCC } from '../../interface/react';
import { trackPageView } from '../../common/ga4';
import { PAYMENTS_PATH, stripeSupportedCountries } from '../../features/Payments/constants';
import PageTitle from '../../components/PageTitle/PageTitle';
import CountryFlag from '../../components/CountryFlag/CountryFlag';
import i18n from '../../i18n';
import styles from './Home.module.scss';

interface HomeProps {}

const paperAttrs = {
	p: {
		xs: 2,
		sm: 4,
	},
	display: 'flex',
	flexDirection: 'column',
	gap: 2,
};

const Home: ReactFCC<HomeProps> = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const gotoPagePayments = () => {
		navigate(`${PAYMENTS_PATH}/products`);
	};

	const gotoPageExtensionAuth = () => {
		navigate(`${PAYMENTS_PATH}/verification`);
	};

	useEffect(() => {
		trackPageView();
	}, []);

	return (
		<Box
			data-testid="home-component"
			sx={{
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			<Box sx={{ pb: { xs: 10, sm: 10 } }}>
				<Paper
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'stretch',
						gap: {
							xs: 5,
							sm: 10,
						},
						background: 'transparent',
					}}
					elevation={0}
				>
					<Paper
						className={styles.rotatingBackground}
						sx={{
							...paperAttrs,
							// background: 'linear-gradient(106deg, #2196F3 30%, #53f1ff 90%)', // linear-gradient(106deg, #fe6b8b 30%, #ff8e53 90%)',
							borderRadius: 0,
							// boxShadow: '0 0 10px rgba(0,0,0,0.2)',
							pt: {
								xs: 10,
								sm: 15,
							},
							pb: {
								xs: 9,
								sm: 14,
							},
							gap: {
								xs: 6,
								sm: 8,
							},
						}}
						elevation={5}
					>
						<PageTitle
							title={t('home.title')}
							font="comfortaa"
							sx={{
								color: 'white',
								textShadow: '4px 4px 10px rgba(0,0,0,0.2)',
								textAlign: 'center',
								fontSize: {
									xs: '2.125rem',
									sm: '4rem',
								},
								fontWeight: '700',
							}}
						/>
						<Button
							variant="outlined"
							size="large"
							sx={{
								display: 'block',
								mx: 'auto',
								textTransform: 'none',
								borderColor: 'white',
								color: 'white',
								boxShadow: '0 0 10px rgba(0,0,0,0.2)',
								'&.hover': {
									border: '1px solid #fff !important',
									color: 'white',
								},
							}}
							onClick={gotoPagePayments}
						>
							{t('home.view_all_products')}
						</Button>
						<Button
							variant="contained"
							size="large"
							color="primary"
							sx={{
								mt: -4,
								mx: 'auto',
								textTransform: 'none',
								borderColor: 'white',
								color: 'white',
								boxShadow: '0 0 10px rgba(0,0,0,0.2)',
								'&.hover': {
									border: '1px solid #fff !important',
									color: 'white',
								},
							}}
							startIcon={<InstallDesktopIcon />}
							endIcon={<ArrowForwardIcon />}
							onClick={gotoPageExtensionAuth}
						>
							{t('home.login_to_browser_extension')}
						</Button>
					</Paper>
					<Container>
						<Box>
							<Typography
								variant="h6"
								component="h2"
								gutterBottom
								// sx={{
								// 	mt: {
								// 		xs: 2,
								// 		sm: 5,
								// 	},
								// }}
							>
								{t('home.supported_countries')}
							</Typography>
							<Typography
								variant="caption"
								component="h3"
								sx={{
									mb: {
										xs: 2,
										sm: 5,
									},
								}}
							>
								{t('home.supported_countries_description')}
							</Typography>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									flexWrap: 'wrap',
									rowGap: {
										xs: 1,
										sm: 2,
									},
									columnGap: {
										xs: 0,
										sm: 2,
									},
								}}
							>
								{stripeSupportedCountries.map((countryCode) => {
									const regionNames = new Intl.DisplayNames([i18n.language], { type: 'region' });
									const countryName = regionNames.of(countryCode);
									return (
										<Box
											key={countryCode}
											sx={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												gap: 1.5,
												width: {
													xs: '50%',
													sm: 200,
												},
											}}
										>
											<CountryFlag countryId={countryCode} />
											<Typography variant="body2">{countryName}</Typography>
										</Box>
									);
								})}
							</Box>
						</Box>
					</Container>
				</Paper>
			</Box>
		</Box>
	);
};

export default Home;
