import React from 'react';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import RemoveModeratorIcon from '@mui/icons-material/RemoveModerator';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import ErrorIcon from '@mui/icons-material/Error';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';

export enum VERIFICATION_ACTIONS {
	INSTALLATION_CHECK = 'extension_installation_check',
	SET_AUTHENTICATION = 'extension_set_authentication',
	UNSET_AUTHENTICATION = 'extension_unset_authentication',
	AUTHENTICATION_CHECK = 'extension_authentication_check',
}

export interface StatusesObj {
	message: string;
	icon: React.ReactNode;
}

export const statuses: {
	[key: string]: StatusesObj;
} = {
	processing: {
		message: 'Processing',
		icon: <HourglassBottomIcon fontSize="inherit" color="primary" />,
	},
	extension_installation_check: {
		message: 'Checking extension installation',
		icon: <HourglassBottomIcon fontSize="inherit" color="primary" />,
	},
	extension_installation_check_success: {
		message: 'Extension is installed',
		icon: <CloudDoneIcon fontSize="inherit" color="success" />,
	},
	extension_installation_check_failed: {
		message: 'Extension is not installed',
		icon: <FileDownloadOffIcon fontSize="inherit" color="error" />,
	},
	user_check: {
		message: 'Checking user',
		icon: <HourglassBottomIcon fontSize="inherit" color="primary" />,
	},
	user_check_success: {
		message: 'User is logged in',
		icon: <PersonIcon fontSize="inherit" color="success" />,
	},
	user_check_failed: {
		message: 'User is not logged in',
		icon: <PersonOffIcon fontSize="inherit" color="error" />,
	},
	extension_auth_check: {
		message: 'Checking extension Authentication',
		icon: <HourglassBottomIcon fontSize="inherit" color="primary" />,
	},
	extension_auth_check_success: {
		message: 'Extension is authenticated',
		icon: <VerifiedUserIcon fontSize="inherit" color="success" />,
	},
	extension_auth_check_failed: {
		message: 'Extension is not authenticated',
		icon: <RemoveModeratorIcon fontSize="inherit" color="error" />,
	},
	extension_sign_in: {
		message: 'Signing into the extension',
		icon: <HourglassBottomIcon fontSize="inherit" color="primary" />,
	},
	extension_sign_in_success: {
		message: 'Sign in successful',
		icon: <VerifiedUserIcon fontSize="inherit" color="success" />,
	},
	extension_sign_in_failed: {
		message: 'Sign in failed',
		icon: <RemoveModeratorIcon fontSize="inherit" color="error" />,
	},
	error: {
		message: 'Error',
		icon: <ErrorIcon fontSize="inherit" color="error" />,
	},
};
