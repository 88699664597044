import React from 'react';
import { Route, Routes as ReactRoutes } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import RouteConfig from './interface';

const renderRoutes = (mainRoutes: RouteConfig[]) => {
	const Routes = () => {
		const layouts = mainRoutes.map(({ layout: Layout, component, path, exact, isPublic }: RouteConfig) => {
			Layout = Layout || React.Fragment;
			return (
				<Route
					path={`${path}${exact ? '' : '/*'}`}
					key={path}
					element={
						<ProtectedRoute isPublic={isPublic}>
							<Layout>{component}</Layout>
						</ProtectedRoute>
					}
				/>
			);
		});
		return <ReactRoutes>{layouts}</ReactRoutes>;
	};
	return Routes;
};

export default renderRoutes;
