import React from 'react';
import { Avatar } from '@mui/material';
import Lottie from 'lottie-react';
import { ReactFCC } from '../../interface/react';
import lottieAnimation from './Animation-1696747584027.json';

interface LoadingProps {}

const Loading: ReactFCC<LoadingProps> = () => {
	return (
		<Avatar
			sx={{
				width: 100,
				height: 100,
				my: 5,
				mx: 'auto',
				backgroundColor: 'transparent',
			}}
		>
			<Lottie animationData={lottieAnimation} />
		</Avatar>
	);
};

export default Loading;
