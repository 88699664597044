import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { ReactFCC } from '../../../../interface/react';
import Loading from '../../../../components/Loading/Loading';
import useAuth from '../../useAuth';
import LoginBox from './LoginBox';
import PageTitle from '../../../../components/PageTitle/PageTitle';

interface LoginProps {}

const Login: ReactFCC<LoginProps> = () => {
	const [searchParams] = useSearchParams();
	const { user, isLoadingAuth } = useAuth();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const redirect = searchParams.get('redirect') || '/';

	useEffect(() => {
		if (user) {
			navigate(redirect);
		}
	}, [user, navigate, redirect]);

	if (isLoadingAuth) {
		return (
			<Box sx={{ textAlign: 'center' }}>
				<Loading />
			</Box>
		);
	}

	return (
		<Box data-testid="login-component">
			<Box
				sx={{
					textAlign: 'center',
					py: {
						xs: 8,
						sm: 10,
					},
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					gap: 3,
				}}
			>
				<PageTitle title={t(`login.title`)} />
				<Typography variant="h5" sx={{ maxWidth: 600 }}>
					{t(`login.subtitle`)}
				</Typography>
				{user ? <div>Logged in as {user?.displayName}</div> : <LoginBox />}
			</Box>
		</Box>
	);
};

export default Login;
