import React from 'react';
import { ReactFCC } from '../../interface/react';
import styles from './CountryFlag.module.scss';
import flagIconSvg from './flagIcons.svg';

interface CountryFlagProps {
	countryId: string;
}

const CountryFlag: ReactFCC<CountryFlagProps> = ({ countryId }) => {
	const countryIdUpperCase = countryId?.toLocaleUpperCase();

	return (
		<img
			className={`${styles.Flag} ${styles[`Flag--country${countryIdUpperCase}`]}`}
			src={flagIconSvg}
			alt={countryIdUpperCase}
			loading="lazy"
		/>
	);
};

export default CountryFlag;
