import React from 'react';
import { Box, Button, CardMedia, Paper, Typography } from '@mui/material';
import { ReactFCC } from '../../interface/react';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';

interface FooterProps {}

const Footer: ReactFCC<FooterProps> = () => {
	return (
		<footer data-testid="footer-component">
			<Paper
				sx={{
					position: 'fixed',
					bottom: 0,
					left: 0,
					right: 0,
					px: {
						xs: 2,
						sm: 3,
					},
					py: 1,
					textAlign: 'center',
					borderTop: '1px solid',
					borderColor: 'divider',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center',
					borderRadius: 0,
					backgroundColor: 'myCustomColor.bgWhite.light',
					color: 'myCustomColor.textBlack.light',
				}}
				elevation={3}
			>
				<a href="https://form.jotform.com/233024147058450" target="_black">
					<Button size="small">Contact Us</Button>
				</a>
				<Typography variant="caption">&copy; 010Pixel, {new Date().getFullYear()}</Typography>
				<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
					<a href="https://stripe.com/" target="_black">
						<CardMedia
							component="img"
							sx={{
								width: {
									xs: 100,
									sm: 150,
								},
								borderRadius: 1.25,
								overflow: 'hidden',
								backgroundColor: '#9ba400',
								filter: 'invert(1)',
							}}
							src="/assets/images/powered-by-stripe.png"
							alt="powered-by-stripe.png"
						/>
					</a>
					<LanguageSwitcher />
				</Box>
			</Paper>
		</footer>
	);
};

export default Footer;
