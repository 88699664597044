import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Button, Card, CardContent, CardHeader, CardMedia, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactFCC } from '../../../../../interface/react';
import { Product } from '../../../interface';
import usePayments from '../../../usePayments';
import { copyContent } from '../../../utils';
import PricingTable from '../PricingTable/PricingTable';
import ProductPriceLoader from '../../../components/ProductPricesLoader/ProductPricesLoader';

interface PricingBoxProps {
	product: Product;
	onClose?: () => void;
}

const PricingBox: ReactFCC<PricingBoxProps> = (props) => {
	const { t } = useTranslation();
	const { product, onClose } = props;
	const { productPrices } = usePayments();
	const [open, setOpen] = React.useState(true);

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (!open) {
			setTimeout(() => {
				onClose?.();
			}, 300);
		}
	}, [open, onClose]);

	const actionIcon = onClose ? (
		<IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
			<CloseIcon />
		</IconButton>
	) : null;

	return (
		<Card sx={{ m: 2, position: 'relative', overflow: 'hidden' }} elevation={0}>
			<CardHeader
				avatar={
					<Avatar
						sx={{
							width: 32,
							height: 32,
							backgroundColor: 'transparent',
						}}
					>
						<CardMedia component="img" image={product.images[0]} alt={product.name} sx={{ width: 32, height: 32 }} />
					</Avatar>
				}
				title={
					<Typography variant="h5" sx={{ mr: 2 }}>
						{product.name}
						<Button
							size="small"
							sx={{
								ml: 1,
								fontSize: '0.65rem',
								py: 0,
								px: 0.75,
								minWidth: 'auto',
								textTransform: 'none',
							}}
							onClick={() => {
								copyContent(product.id);
							}}
						>
							{t('common.copy_id')}
						</Button>
					</Typography>
				}
				action={actionIcon}
			/>
			<CardContent
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					justifyContent: 'space-between',
					pt: 0,
					'&:last-child': {
						paddingBottom: 2,
					},
				}}
			>
				<Typography variant="body1" color="text.secondary">
					{product.description}
				</Typography>

				<ProductPriceLoader product={product}>
					<PricingTable product={product} productPrices={productPrices} />
				</ProductPriceLoader>
			</CardContent>
		</Card>
	);
};

export default PricingBox;
