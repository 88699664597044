import React from 'react';
import RouteConfig from '../../../routes/interface';
import renderRoutes from '../../../routes/utils';
import ProductOverview from './compoents/ProductOverview/ProductOverview';
import ProductPlans from './compoents/ProductPlans/ProductPlans';
import ProductBuy from './compoents/ProductBuy/ProductBuy';

const routes: RouteConfig[] = [
	{
		title: 'Overview',
		path: '/',
		component: <ProductOverview />,
		exact: true,
		isPublic: true,
	},
	{
		title: 'Plans',
		path: '/plans',
		component: <ProductPlans />,
		exact: true,
		isPublic: true,
	},
	{
		title: 'Buy',
		path: '/buy',
		component: <ProductBuy />,
		exact: true,
		isPublic: true,
	},
];

export const Routes = renderRoutes(routes);

export default routes;
