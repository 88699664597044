import { useQueries, useQuery } from 'react-query';
import { getBillingPortalLink, getCustomClaimRole, getProductPrice, getProducts, getSubscriptions } from './service';

const reactQueryDefaultOptions = {
	cacheTime: 10000,
	refetchOnWindowFocus: false,
};

export const useGetProducts = (options: any) => {
	return useQuery('products', getProducts, { ...reactQueryDefaultOptions, ...options });
};

export const useGetProductPrices = (params: any, options: any) => {
	const { productIds } = params;
	const prices = productIds().map((productId: string) => {
		return {
			queryKey: ['product-price', productId],
			queryFn: async () => getProductPrice(productId),
			...reactQueryDefaultOptions,
			...options,
		};
	});
	return useQueries(prices);
};

export const useGetSubscription = (params: any, options: any) => {
	return useQuery(['subscription', params.userId], async () => getSubscriptions(params.userId), {
		...reactQueryDefaultOptions,
		...options,
	});
};

export const useGetPriceForProduct = (params: any, options: any) => {
	return useQuery(['price', params.productId], async () => getProductPrice(params.productId), {
		...reactQueryDefaultOptions,
		...options,
	});
};

export const useGetCustomClaimRole = (params: any, options: any) => {
	return useQuery(['custom-claim-role', params.user?.uid], async () => getCustomClaimRole(params.user), {
		...reactQueryDefaultOptions,
		...options,
	});
};

export const useGetBillingPortalLink = (options: any) => {
	return useQuery('custom-claim-role', getBillingPortalLink, {
		...reactQueryDefaultOptions,
		...options,
	});
};
