import { httpsCallable } from 'firebase/functions';
import { addDoc, collection, documentId, getDocs, query, where } from 'firebase/firestore';
import { auth, db, functions } from '../auth/service/firebase';
import { CustomerPortalResponse, Product, ProductPrice, Subscription } from './interface';
import { createPortalLinkCloudFunctionName } from './constants';

export const getProducts = async (): Promise<Product[]> => {
	const productsRef = collection(db, 'products');
	const conditions = where('active', '==', true);
	const q = query(productsRef, conditions);
	const querySnapshot = await getDocs(q);
	return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id, prices: [] as ProductPrice[] } as Product));
};

export const getProductPrice = async (productId: string): Promise<ProductPrice[]> => {
	const productPriceRef = collection(db, `products/${productId}/prices`);
	const conditions = where('active', '==', true);
	const q = query(productPriceRef, conditions);
	const querySnapshot = await getDocs(q);
	return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as ProductPrice));
};

export const addCheckoutSession = async (userId: string, checkoutSession: any): Promise<any> => {
	const checkoutSessionRef = collection(db, `customers/${userId}/checkout_sessions`);
	const data = await addDoc(checkoutSessionRef, checkoutSession);
	return data;
};

export const getDocFromPathById = async (path: any, docId: string): Promise<any> => {
	const productsRef = collection(db, path);
	const conditions = where(documentId(), '==', docId);
	const q = query(productsRef, conditions);
	const querySnapshot = await getDocs(q);
	return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))[0];
};

export const getSubscriptions = async (userId: string): Promise<Subscription[]> => {
	const subscriptionsRef = collection(db, `customers/${userId}/subscriptions`);
	const conditions = where('status', 'in', ['active', 'trialing']);
	const q = query(subscriptionsRef, conditions);
	const querySnapshot = await getDocs(q);
	return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Subscription));
};

export const getCustomClaimRole = async (currentUser: any) => {
	await currentUser.getIdToken(true);
	const decodedToken = await currentUser.getIdTokenResult();
	return decodedToken.claims.stripeRole;
};

export const getBillingPortalLink = async (): Promise<CustomerPortalResponse | unknown> => {
	const stripePaymentCreatePortalLink = httpsCallable(functions, createPortalLinkCloudFunctionName);
	const result = await stripePaymentCreatePortalLink({ returnUrl: window.location.href });
	return result?.data;
};

export const getIdToken = async () => {
	const idToken = auth.currentUser?.getIdToken(true);
	return idToken;
};
